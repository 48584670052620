import React from "react";
import { Form } from "react-bootstrap";
import { InputBox } from "./EditableInput.styles";
import moment from "moment";

const EditableInput = (props) =>
{
	const {
    placeholder,
    className,
    disabled,
    value = "",
    type = "text",
    onChange = () =>
    {},
	} = props;

	const renderValue = (value) =>
	{
		switch (type)
		{
		case "date":
			return moment(value).format("YYYY-MM-DD");
		default:
			return value;
		}
	};

	const textStringOnChange = (v) =>
	{
		onChange(v);
	};

	return (
		<Form.Group controlId="formBasicEmail">
			<Form.Label>{placeholder}</Form.Label>
			<InputBox
				className={className || "mr-sm-12"}
				type={type}
				placeholder={placeholder}
				disabled={disabled}
				value={renderValue(value)}
				onChange={textStringOnChange}
			/>
		</Form.Group>
	);
};

export default EditableInput;
