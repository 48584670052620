let basepath = "";

const buildUrl = (endpoint) =>
{
	if(!basepath) return endpoint;

	// already contains schema
	if(endpoint.indexOf("http") === 0) return endpoint;

	return basepath + endpoint;
};

export const getAPI = async(endpoint, options = null) =>
{
	const validateSuccess = !(options && options.skipValidate);
	const apiKey = getAPIKey();

	const req = new Request(buildUrl(endpoint), {
    method: "get",
    headers: {
      "x-api-key": apiKey,
      "Access-Control-Allow-Credentials": true,
    }
	});

	const res = await fetch(req);

	const json = await res.json();

	if(!json.status === "success" && validateSuccess)
		throw new Error(json.msg || "An error occurred when loading " + endpoint);

	return json;
};

export const postAPI = async(
	endpoint,
	data = {},
	options = null,
	method = null
) =>
{
	const validateSuccess = !(options && options.skipValidate);
	const apiKey = getAPIKey();

	const req = new Request(buildUrl(endpoint), {
    method: method || "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
      "x-api-key": apiKey,
    },
    body: JSON.stringify(data),
	});

	const res = await fetch(req);

	const json = await res.json();

	if(!json.status === "success" && validateSuccess)
		throw new Error(json.msg || "An error occurred when saving to " + endpoint);

	return json;
};

const getAPIKey = () =>
{
	const adminConfig = localStorage.getItem("GC_ADMIN");

	try
	{

		const GC_ADMIN = JSON.parse(adminConfig);

		return GC_ADMIN.apiKey;

	}
	catch (e)
	{

		return null;

	}
};

export const setAPIBasepath = env =>
{

	if(env === "production")
		basepath = "https://api.getconveyancer.co.uk";
	else
		basepath = "http://localhost:3002";

};

export const getAPIBasepath = () => basepath;

setAPIBasepath(process.env.NODE_ENV);