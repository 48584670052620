import React, { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Table, Row, Col, Button, ListGroup, Image } from "react-bootstrap";
import StatusBadge from "./../../common/StatusBadge";
import SelectList from "./../../common/selectList";
import TransactionIcon from "./../../common/transactionIcon";
import { ArrowUpRightSquareFill } from "react-bootstrap-icons";
import { colors } from "./../../../constants/colors";
import { gaNiceFormatPrice } from "./../../../utils/common";

const TableComponent = ({tableConfig, content, lookup, disableEdit, editModeUpdate}) =>
{

	const [ state, updateState ] = useState({ editingRow: null });

	const contentFormat = (type, content, id, disabled, onSelectChange, imageSrc, contentId) =>
	{
		switch (type)
		{
		case "date":
			return moment(content).format("DD/MM/YYYY, h:mm:ss a");
		case "link":
			return <Link to={`/leads/${id}`} >{content}</Link>;
		case "badge":
			return <StatusBadge status={content} />;
		case "select":
			return <SelectList lookup={lookup} content={content} disabled={disabled} onChange={e => onSelectChange(id, e.target.value)} editingRow={editingRow}/>;
		case "thumbnail":
			return <Row><Col xs="5"><Link to={`/conveyancers/${contentId}`} target="_blank">{content}<ArrowUpRightSquareFill className="ml-2" /></Link></Col><Col xs="7"><Image src={imageSrc} width="100"/></Col></Row>;
		case "array":
			return <ListGroup>{content.map((c,i) => <ListGroup.Item key={i} style={{border: "none"}}>{c}</ListGroup.Item>)}</ListGroup>;
		case "transactions":
			return <TransactionIcon
				transactionHasBuy={content.find(c => c === "Buy")}
				transactionHasSell={content.find(c => c === "Sell")}
			/>;
			case "quote":
				return "£" + gaNiceFormatPrice(content, 2);
			default:
				return content;
		}
	};

	const resolveContent = (obj, string) =>
	{

		// convert indexes to properties
		// strip a leading dot
		const keys = ((string.replace(/\[(\w+)\]/g, ".$1")).replace(/^\./, "")).split(".");
		let currObj = obj;

		for (let i = 0, n = keys.length; i < n; ++i)
		{
			let k = keys[i];

			if(k in currObj)
				currObj = currObj[k];
			else
				return;
		}

		return currObj;

	};

	const toggleEditState = (index, isCancel, id) =>
	{

		updateState({editingRow: index,});

		editModeUpdate("shortlist", isCancel, id);

	};

	const tableTitles = tableConfig.map( t => t.name );
	const tableKeys = tableConfig.map( t => t.key );
	const { editingRow } = state;

	return (
		<Table striped hover responsive>
			<thead>
				<tr>
					{ tableTitles.map(title => <th key={title}>{title}</th>)}
				</tr>
			</thead>
			<tbody>
				{ content.map( (c, contentIndex) => (
					<tr key={c.id}>
						{
							tableKeys.map( (k, index) => (<td key={k + "-" + c.id + "-" + index}>{
								<Row key={index}>
									<Col xs="12" className="p-0">
										<Col xs="12" className="p-0">
											{ contentFormat(
												tableConfig[index].type,
												resolveContent(c, k),
												c.id,
												!(tableConfig[index].editable && !disableEdit && (editingRow === contentIndex)),
												tableConfig[index].onSelectChange,
												tableConfig[index].thumbnailSrc ? resolveContent(c, tableConfig[index].thumbnailSrc) : null,
												c.conveyancer && c.conveyancer.id
											) }
										</Col>
										{ tableConfig[index].editable ?
											<Col xs="12" className="p-0">
												{ disableEdit ?
													<span style={{marginLeft: "10px", fontSize: "12px", textDecoration: "underline", cursor: "pointer", color: colors.blue}} onClick={() => toggleEditState(contentIndex, false, resolveContent(c, k))}>
                                                        Edit
													</span>
													:
													""
												}
												{ tableConfig[index].editable && !disableEdit && editingRow === contentIndex
													?
													<Row>
														<Col xs="6" className="p-0">
															<Button
																variant="danger"
																style={{marginRight: "25px"}}
																onClick={() => toggleEditState(null, true)}
															>
                                                                    Cancel
															</Button>
														</Col>
														<Col xs="5" className="p-0 ml-1">
															<Button
																variant="success"
																onClick={() => toggleEditState(null, false, c.id)}
															>
                                                                    Save
															</Button>
														</Col>
													</Row>
													:
													""
												}
											</Col>
											:
											""
										}
									</Col>
								</Row>
							}</td>))
						}
					</tr>
				))}
			</tbody>
		</Table>
	);
};

export default TableComponent;