import React, { useEffect } from "react";
import { Row, Col, Card, Tab, Container } from "react-bootstrap";
import SingleOfficePanel from "./singleOfficePanel";
import QuotesPanel from "../quotes/quotesPanel";
import _ from "underscore";
import TabsMenu from "../../../common/tabsMenu/tabsMenu";
import { postAPI } from "../../../../utils/requestAPI";
import { validateOffice } from "../../../../utils/validations";
import { SectionTitle } from "./../../../common/section/Section";

export default function OfficePanel({
  conveyancer,
  offices,
  office,
  editingOfficeCopy,
  editingOffice,
  editingQuoteCopy,
  editingQuote,
  quotes,
  quote,
  allHooks,
  fetchData,
})
{
	const {
    setOffice,
    setQuotes,
    setEditingOffice,
    setEditingQuote,
    setEditingQuoteCopy,
    setEditingOfficeCopy,
    setLoading,
    setOffices,
	} = allHooks;

	const handleSelectOffice = (id) =>
	{
		const office = offices.find((o) => o.id === id);

		if(office)
		{
			setOffice(office);

			if(office.quotes)

				setQuotes(office.quotes);


			if(editingOffice)

				setEditingOffice(false);

		}
	};

	const handleSaveOffice = async() =>
	{
		const requestParms = ["name", "email", "phone", "address", "postcode"];

		// Update office
		if(office.id)
		{
			const data = _.pick(editingOfficeCopy, requestParms);

			// Validate office payload
			const errors = validateOffice(data);

			if(errors.length > 0) return alert(errors.join(" "));

			const res = await postAPI(
				`/v1/admin/conveyancers/${conveyancer.id}/office/${office.id}`,
				data,
				null,
				"PUT"
			);

			if(res.status === "success")
			{
				setOffice(editingOfficeCopy);
				setEditingOffice(false);
				setEditingQuoteCopy(null);

				const newOffices = offices.map((currentOffice) =>
				{
					if(currentOffice.id === office.id) return editingOfficeCopy;

					return currentOffice;
				});

				setOffices(newOffices);
			}
			else if(res.status === "error")
			{
				alert(res.message);
			}
		}
		else
		{
			// Create new office

			const data = _.mapObject(
				_.pick(editingOfficeCopy, requestParms),
				(key) => key || null
			);

			// Validate office payload
			const errors = validateOffice(data);

			if(errors.length > 0) return alert(errors.join(" "));

			// If the user is creating a new quote at the same time add this quote to the payload
			if(
				editingQuote &&
        editingQuoteCopy &&
        editingQuoteCopy.price_from &&
        editingQuoteCopy.price_to
			)
			{
				const requestParms = [
					"price_from",
					"price_to",
					"gc_poc_fee",
					"gc_ppi_fee",
					"type",
					"quote",
					"leasehold",
					"mortgage",
					"unregistered",
					"shared_ownership",
					"first_time_buyer",
					"affordable",
					"disbursements",
				];

				const formatedQuote = _.mapObject(
					_.pick(editingQuoteCopy, requestParms),
					(key) => key || null
				);

				data.quotes = [formatedQuote];
			}

			const res = await postAPI(
				`/v1/admin/conveyancers/${conveyancer.id}/office`,
				data,
				null
			);

			if(res.status === "success")
			{
				setLoading(true);
				setEditingQuote(false);
				setEditingQuoteCopy(null);
				fetchData();
			}
			else if(res.status === "error")
			{
				alert(res.message);
			}
		}
	};

	const handleCreateNewOffice = () =>
	{
		const emptyOffice = {
      name: "",
      email: "",
      phone: "",
      address: "",
      postcode: "",
		};

		setEditingQuote(false);
		setEditingQuoteCopy(null);
		setQuotes([]);

		setEditingOffice(true);
		setOffice(emptyOffice);
		setEditingOfficeCopy(emptyOffice);
	};

	const handleEditOffice = () =>
	{
		if(editingOffice)
		{
			setEditingOffice(false);
			setEditingOfficeCopy(null);
		}
		else
		{
			setEditingOffice(true);
			setEditingOfficeCopy(office);
		}
	};

	const handleEditingOfficeCopyChange = (key, value) =>
	{
		let finalValue = value;

		if(typeof value === "boolean") finalValue = value === true ? 1 : 0;

		const newEditingOffice = {
      ...editingOfficeCopy,
      [key]: finalValue,
		};

		setEditingOfficeCopy(newEditingOffice);
	};

	const handleDeleteOffice = async() =>
	{
		const confirm = window.confirm(
			"Are you sure you want to delete this office?"
		);

		if(!confirm) return;

		const res = await postAPI(
			`/v1/admin/conveyancers/${conveyancer.id}/office/${office.id}`,
			null,
			null,
			"DELETE"
		);

		if(res.status === "success")
		{
			setLoading(true);
			fetchData();
		}
		else if(res.status === "error")
		{
			alert(res.message);
		}
	};

	const handleCancelEditOffice = () =>
	{
		handleEditOffice();
	};

	const selectOfficeFromKey = (key) =>
	{
		if(key === "NEW_OFFICE")

			handleCreateNewOffice();

		else

			handleSelectOffice(Number(key));

	};

	const displayDefault =
    offices && offices.length > 0 ? _.head(offices).id : "NEW_OFFICES";

	useEffect(() =>
	{
		if(!offices || offices.length === 0)

			handleCreateNewOffice();

	}, []);

	return (
		<>
			<Container>
				<Card className="mt-5">
					<Card.Header>
						<SectionTitle className="mb-0">Offices</SectionTitle>
					</Card.Header>
					<Card.Body>
						<Tab.Container
							defaultActiveKey={displayDefault}
							onSelect={selectOfficeFromKey}
						>
							<Row>
								<Col>
									<TabsMenu
										list={offices}
										currentSelectedElement={office}
										handleCustomButtomClick={handleCreateNewOffice}
										currentEditingCopy={editingOfficeCopy}
										handleSelect={handleSelectOffice}
										buttonPlaceHolder="New Office"
										getTabLabel={(i) => i.address || `Office ${i.id}`}
									/>
								</Col>
							</Row>

							<Row>
								<Col>
									{office && (
										<SingleOfficePanel
											editingOffice={editingOffice}
											editingOfficeCopy={editingOfficeCopy}
											office={office}
											handleEditingOfficeCopyChange={
												handleEditingOfficeCopyChange
											}
											handleEditOffice={handleEditOffice}
											handleSaveOffice={handleSaveOffice}
											handleCancelEditOffice={handleCancelEditOffice}
											handleDeleteOffice={handleDeleteOffice}
										/>
									)}
								</Col>
							</Row>
						</Tab.Container>
					</Card.Body>
				</Card>
			</Container>
			{office && (
				<QuotesPanel
					quotes={quotes}
					quote={quote}
					editingQuoteCopy={editingQuoteCopy}
					editingQuote={editingQuote}
					allHooks={allHooks}
					offices={offices}
					office={office}
					conveyancer={conveyancer}
					fetchData={fetchData}
					editingOffice={editingOffice}
				/>
			)}
		</>
	);
}
