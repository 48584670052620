import styled from "styled-components";
import { Card } from "react-bootstrap";

export const MenuWrapper = styled(Card)`
  flex-direction: row;
  overflow-x: scroll;
  border: none;
  border-radius: 0;
  margin: 0;
`;

export const Tab = styled.div`
  ${({ selected }) =>
		selected
			? "background-color: #2c7be5; color: white;"
			: "background-color: rgba(0, 100, 100, 0.05);"}

  border-radius: 5px 5px 0 0;
  text-align: center;
  padding: 0.5rem 1rem;
  min-width: fit-content;
  margin-top: 0.5rem;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 100, 100, 0.2);
    color: black;
  }
`;
