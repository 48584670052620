import React from "react";
import { Spinner } from "react-bootstrap";

export default function LoadingSpinner()
{
	return (
		<div
			style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
			}}
		>
			<Spinner animation="border" role="status" variant="primary">
				<span className="sr-only">Loading...</span>
			</Spinner>
		</div>
	);
}
