import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Image, Container } from "react-bootstrap";
import { getAPI, postAPI } from "../../utils/requestAPI";
import _ from "underscore";
import FourZeroFour from "../conveyancerDashboard/common/FourZeroFour";
import EditableInput from "../common/editableInput";
import LoadingSpinner from "../conveyancerDashboard/common/loadingSpinner";
import EditableTextarea from "../common/editableTextarea";
import Checkbox from "../common/checkbox";
import OfficePanel from "./common/offices/officePanel";
import { validateConveyancer } from "../../utils/validations";
import { SectionTitle } from "./../common/section/Section";
import AdditionalFees from "./common/AdditionalFees";

const ConveyancerPanel = (props) =>
{
	const [editingConveyancer, setEditingConveyancer] = useState(false);
	const [loading, setLoading] = useState(true);
	const [conveyancer, setConveyancer] = useState(null);
	const [editingConveyancerCopy, setEditingConveyancerCopy] = useState(null);

	const [offices, setOffices] = useState([]);
	const [office, setOffice] = useState(null);
	const [editingOffice, setEditingOffice] = useState(false);
	const [editingOfficeCopy, setEditingOfficeCopy] = useState(null);

	const [quotes, setQuotes] = useState([]);
	const [quote, setQuote] = useState(null);
	const [editingQuote, setEditingQuote] = useState(false);
	const [editingQuoteCopy, setEditingQuoteCopy] = useState(null);

	const [additionalFees, setAdditionalFees] = useState([]);

	useEffect(() =>
	{
		return fetchData();
	}, []);

	const getConveyancerInformation = async() =>
	{
		const { conveyancerId } = props.match.params;

		const res = await getAPI(`/v1/admin/conveyancers/${conveyancerId}/office`);

		if(res && res.data)
		{
			setConveyancer({...res.data, isPPI: !!res.data.pay_per_intro }); // pay_per_intro is overcharged to also hold date in db
			setOffices(res.data.offices);

			try
			{

				if(!res.data.quote_additional_fees)
					setAdditionalFees([]);

				else
					setAdditionalFees(JSON.parse(res.data.quote_additional_fees));
			}
			catch (error)
			{
				console.log(error);
			}

			const firstOffice = res.data.offices[0];

			if(firstOffice)
			{
				setOffice(firstOffice);

				if(firstOffice.quotes && firstOffice.quotes.length > 0)
				{
					setQuotes(firstOffice.quotes);
					setQuote(firstOffice.quotes[0]);
				}
			}
		}
	};

	const fetchData = async() =>
	{
		await getConveyancerInformation();

		setLoading(false);
	};

	const handleEditingConveyancerCopyChange = (key, value) =>
	{
		let finalValue = value;

		if(typeof value === "boolean") finalValue = value === true ? 1 : 0;

		const newEditingConv = {
			...editingConveyancerCopy,
			[key]: finalValue,
		};

		if(key === "isPPI" && !value)
		{
			// enables removing model from DB
			newEditingConv.pay_per_intro = null;
			newEditingConv.pay_per_intro_cap = null;
		}

		setEditingConveyancerCopy(newEditingConv);
	};

	const saveConveyancer = async() =>
	{
		// this flag is purely for validation purposes in both cases (true/false)
		const isPPI = editingConveyancerCopy.isPPI;

		const requestParms = [
			"name",
			"email",
			"phone",
			"logo",
			"rating",
			"description",
			"cover_england",
			"cover_wales",
			"cover_scotland",
			"cover_ni",
			"accepting_leads",
			"enabled",
			"pay_per_intro",
			"pay_per_intro_cap",
			"perfect_portal_id",
			"highlights",
			"awards",
			"summary"
		];

		const data = _.pick(editingConveyancerCopy, requestParms);

		// Validate conveyancer data
		const errors = validateConveyancer(data, isPPI);

		if(errors.length > 0) return alert(errors.join(", "));

		const res = await postAPI(
			`/v1/admin/conveyancers/${conveyancer.id}`,
			data,
			null,
			"PUT"
		);

		if(res.status === "success") setConveyancer(editingConveyancerCopy);

		handleEditingConveyancer();
	};

	const handleEditingConveyancer = () =>
	{
		if(!editingConveyancer)
		{
			setEditingConveyancerCopy(conveyancer);
			setEditingConveyancer(!editingConveyancer);
		}
		else
		{
			setEditingConveyancer(!editingConveyancer);
			setEditingConveyancerCopy(null);
		}
	};

	const handleHighlights = (key, value) =>
	{
		let highlights = editingConveyancerCopy.highlights ? JSON.parse(editingConveyancerCopy.highlights) : [];

		try
		{
			if(!highlights || !highlights.length)
				highlights = [key];

			else if(highlights.length && value == true)
				highlights.push(key);

			else if(highlights.length && value == false)
				highlights = highlights.filter(h => h !== key);

			handleEditingConveyancerCopyChange("highlights", JSON.stringify(highlights));
		}
		catch (error)
		{
			console.log(error);
		}
	};

	const HighlightCheckboxes = ({ handleHighlights }) =>
	{
		const highlights = [
			{
				label: "No sale, no fee guarantee",
				value: "noSale",
			},
			{
				label: "Faster than average",
				value: "fasterThanAverage",
			},
			{
				label: "Customer service",
				value: "customerService",
			},
			{
				label: "Award winning",
				value: "awardWinning",
			},
		];

		const validateHighlightCheck = (highlight) =>
		{
			if(editingConveyancer)
			{
				let highlights = editingConveyancerCopy.highlights ? JSON.parse(editingConveyancerCopy.highlights) : [];

				return highlights.includes(highlight);
			}

			if(!editingConveyancer)
			{
				let highlights = conveyancer.highlights ? JSON.parse(conveyancer.highlights) : [];

				return highlights.includes(highlight);
			}
		};

		return highlights.map((highlight, index) =>
			<Col key={index}>
				<Checkbox
					label={highlight.label}
					disabled={!editingConveyancer}
					controlId={highlight.value}
					value={validateHighlightCheck(highlight.value)}
					onChange={(e) =>
						handleHighlights(
							highlight.value,
							e.target.checked
						)
					}
				/>
			</Col>
		);
	};

	/**
	 * This function is commented out as we have disabled te delete button currently
		const handleDeleteConveyancer = async() =>
		{
			const confirm = window.confirm(
				"Are you sure you want to delete this conveyancer?"
			);

			if(!confirm) return;

			const res = await postAPI(
				`/v1/admin/conveyancers/${conveyancer.id}`,
				null,
				null,
				"DELETE"
			);

			if(res.status === "success")
			{
				setLoading(true);
				fetchData();
			}
			else if(res.status === "error")
			{
				alert(res.message);
			}
		};
	*/

	const allHooks = {
    setEditingConveyancer,
    setLoading,
    setConveyancer,
    setEditingConveyancerCopy,

    setOffice,
    setOffices,
    setEditingOffice,
    setEditingOfficeCopy,

    setQuote,
    setQuotes,
    setEditingQuote,
    setEditingQuoteCopy,

		setAdditionalFees,
	};

	if(!loading && !conveyancer)

		return <FourZeroFour />;


	if(loading) return <LoadingSpinner />;

	return (
		<>
			<Container>
				<Row className="mt-5">
					<Col md={2} className="pr-0">
						<Image
							style={{ maxHeight: "100px", maxWidth: "100%" }}
							src={conveyancer.logo}
							thumbnail
						/>
					</Col>
					<Col md={9} style={{ display: "flex", alignItems: "center" }}>
						<SectionTitle className="mb-0">{conveyancer.name}</SectionTitle>
					</Col>
				</Row>
				<Card className="mt-3">
					<Card.Header>
						<SectionTitle className="mb-0">Details</SectionTitle>
					</Card.Header>
					<Card.Body>
						<Row className="mb-3">
							<Col>
								<EditableInput
									placeholder="Name"
									disabled={!editingConveyancer}
									value={
										editingConveyancer
											? editingConveyancerCopy["name"]
											: conveyancer["name"]
									}
									onChange={(e) =>
										handleEditingConveyancerCopyChange("name", e.target.value)
									}
								/>
							</Col>
							<Col>
								<EditableInput
									placeholder="Email"
									disabled={!editingConveyancer}
									value={
										editingConveyancer
											? editingConveyancerCopy["email"]
											: conveyancer["email"]
									}
									onChange={(e) =>
										handleEditingConveyancerCopyChange("email", e.target.value)
									}
								/>
							</Col>
							<Col>
								<EditableInput
									placeholder="Phone"
									disabled={!editingConveyancer}
									value={
										editingConveyancer
											? editingConveyancerCopy["phone"]
											: conveyancer["phone"]
									}
									onChange={(e) =>
										handleEditingConveyancerCopyChange("phone", e.target.value)
									}
								/>
							</Col>
						</Row>

						<Row>
							<Col>
								<EditableTextarea
									placeholder="Description"
									disabled={!editingConveyancer}
									value={
										editingConveyancer
											? editingConveyancerCopy["description"]
											: conveyancer["description"]
									}
									onChange={(e) =>
										handleEditingConveyancerCopyChange(
											"description",
											e.target.value
										)
									}
								/>
							</Col>
						</Row>

						<Row>
							<Col>
								<EditableTextarea
									placeholder="Summary"
									disabled={!editingConveyancer}
									value={
										editingConveyancer
											? editingConveyancerCopy["summary"]
											: conveyancer["summary"]
									}
									onChange={(e) =>
										handleEditingConveyancerCopyChange("summary", e.target.value)
									}
								/>
							</Col>

							<Col>
								<EditableTextarea
									placeholder="Awards"
									disabled={!editingConveyancer}
									value={
										editingConveyancer
											? editingConveyancerCopy["awards"]
											: conveyancer["awards"]
									}
									onChange={(e) =>
										handleEditingConveyancerCopyChange("awards", e.target.value)
									}
								/>
							</Col>
						</Row>

						<Row>
							<Col sm={4}>
								<EditableInput
									placeholder="Rating"
									disabled={!editingConveyancer}
									value={
										editingConveyancer
											? editingConveyancerCopy["rating"]
											: conveyancer["rating"]
									}
									onChange={(e) =>
										handleEditingConveyancerCopyChange("rating", e.target.value)
									}
								/>
							</Col>

							<Col sm={8}>
								<EditableInput
									placeholder="Logo"
									disabled={!editingConveyancer}
									value={
										editingConveyancer
											? editingConveyancerCopy["logo"]
											: conveyancer["logo"]
									}
									onChange={(e) =>
										handleEditingConveyancerCopyChange("logo", e.target.value)
									}
								/>
							</Col>
						</Row>

						<Row>
							<Col>
								<Checkbox
									label="Accepting leads"
									disabled={!editingConveyancer}
									controlId="conveyancer-accepting-leads"
									value={
										editingConveyancer
											? editingConveyancerCopy["accepting_leads"] === 1
											: conveyancer["accepting_leads"] === 1
									}
									onChange={(e) =>
										handleEditingConveyancerCopyChange(
											"accepting_leads",
											e.target.checked
										)
									}
								/>
							</Col>

							<Col>
								<Checkbox
									label="Enabled"
									controlId="conveyancer-enabled"
									disabled={!editingConveyancer}
									value={
										editingConveyancer
											? editingConveyancerCopy["enabled"] === 1
											: conveyancer["enabled"] === 1
									}
									onChange={(e) =>
										handleEditingConveyancerCopyChange(
											"enabled",
											e.target.checked
										)
									}
								/>
							</Col>

							<Col>
								<Checkbox
									label="Cover England"
									controlId="conveyancer-cover-england"
									disabled={!editingConveyancer}
									value={
										editingConveyancer
											? editingConveyancerCopy["cover_england"] === 1
											: conveyancer["cover_england"] === 1
									}
									onChange={(e) =>
										handleEditingConveyancerCopyChange(
											"cover_england",
											e.target.checked
										)
									}
								/>
							</Col>

							<Col>
								<Checkbox
									label="Cover Wales"
									controlId="conveyancer-cover-wales"
									disabled={!editingConveyancer}
									value={
										editingConveyancer
											? editingConveyancerCopy["cover_wales"] === 1
											: conveyancer["cover_wales"] === 1
									}
									onChange={(e) =>
										handleEditingConveyancerCopyChange(
											"cover_wales",
											e.target.checked
										)
									}
								/>
							</Col>

							<Col>
								<Checkbox
									label="Cover Scotland"
									controlId="conveyancer-cover-scorland"
									disabled={!editingConveyancer}
									value={
										editingConveyancer
											? editingConveyancerCopy["cover_scotland"] === 1
											: conveyancer["cover_scotland"] === 1
									}
									onChange={(e) =>
										handleEditingConveyancerCopyChange(
											"cover_scotland",
											e.target.checked
										)
									}
								/>
							</Col>

							<Col>
								<Checkbox
									label="Cover NI"
									controlId="conveyancer-cover-ni"
									disabled={!editingConveyancer}
									value={
										editingConveyancer
											? editingConveyancerCopy["cover_ni"] === 1
											: conveyancer["cover_ni"] === 1
									}
									onChange={(e) =>
										handleEditingConveyancerCopyChange(
											"cover_ni",
											e.target.checked
										)
									}
								/>
							</Col>
						</Row>
						<Row style={{ marginLeft: "2px", marginBottom: "10px", marginTop: "10px"}}>
							Highlights:
						</Row>
						<Row>
							<HighlightCheckboxes handleHighlights={handleHighlights}/>
						</Row>
						<Row>
							<Col>
								<Checkbox
									label="PPI"
									controlId="conveyancer-ppi"
									disabled={!editingConveyancer}
									value={
										editingConveyancer
											? !!editingConveyancerCopy["isPPI"]
											: !!conveyancer["isPPI"]
									}
									onChange={(e) =>
										handleEditingConveyancerCopyChange(
											"isPPI",
											e.target.checked
										)
									}
								/>
							</Col>
							{
								((editingConveyancer && editingConveyancerCopy["isPPI"]) || conveyancer["isPPI"])
									?
									<>
										<Col>
											<EditableInput
												placeholder="PPI Start Date"
												controlId="conveyancer-ppi-date"
												type="date"
												disabled={!editingConveyancer}
												value={
													editingConveyancer
														? (editingConveyancerCopy["pay_per_intro"])
														: (conveyancer["pay_per_intro"])
												}
												onChange={(e) =>
													handleEditingConveyancerCopyChange("pay_per_intro", e.target.value)
												}
											/>
										</Col>
										<Col>
											<EditableInput
												placeholder="PPI Balance Cap"
												controlId="conveyancer-ppi-cap"
												disabled={!editingConveyancer}
												value={
													editingConveyancer
														? editingConveyancerCopy["pay_per_intro_cap"] ?? ""
														: conveyancer["pay_per_intro_cap"]
												}
												onChange={(e) =>
													handleEditingConveyancerCopyChange("pay_per_intro_cap", e.target.value)
												}
											/>
										</Col>
									</>
									:
									null
							}
						</Row>

						{conveyancer["perfect_portal_id"] ? (
							<Row>
								<Col sm={4}>
									<EditableInput
										placeholder="Perfect Portal ID"
										disabled="true"
										value={conveyancer["perfect_portal_id"]}
									/>
								</Col>
							</Row>
						) : null}

					</Card.Body>
					<Card.Footer>
						{!editingConveyancer && (
							<>
								<Button className="mr-3" onClick={handleEditingConveyancer}>Edit</Button>
								{/*
									<-- Removed for now -->
									<Button variant="danger" onClick={handleDeleteConveyancer}>
									Delete
									</Button>
								*/}
							</>
						)}
						{editingConveyancer && (
							<Button
								variant="success"
								className="mr-3"
								onClick={saveConveyancer}
							>Save</Button>
						)}
						{editingConveyancer ?
							<Button variant="secondary" onClick={handleEditingConveyancer}>Cancel</Button>
							:
							""
						}
					</Card.Footer>
				</Card>
			</Container>
			<OfficePanel
				offices={offices}
				office={office}
				editingOfficeCopy={editingOfficeCopy}
				editingOffice={editingOffice}
				conveyancer={conveyancer}
				quotes={quotes}
				quote={quote}
				editingQuoteCopy={editingQuoteCopy}
				editingQuote={editingQuote}
				allHooks={allHooks}
				fetchData={fetchData}
			/>
			<AdditionalFees
				additionalFees={additionalFees}
				conveyancer={conveyancer}
				fetchData={fetchData}
			/>
		</>
	);
};

export default ConveyancerPanel;
