export const GET_AGENT_BASE_URL = process.env.NODE_ENV === "production" ? "https://www.getagent.co.uk" : "http://localhost:3000";

export const ALL_TRANSACTION_TYPES = [
	{
        name: "Buy",
        id: 1
	},
	{
        name: "Sell",
        id: 2
	}
];

export const SHORTLIST_STATUS = {
    0: "NEW",
    100: "CONTACTED",
    200: "INSTRUCTED",
};

export const LEAD_STATUS = {
    0: {
        name: "NEW",
        badge: "success"
    },
    100: {
        name: "CONTACTED",
        badge: "secondary"
    },
    200: {
        name: "INSTRUCTED",
        badge: "primary"
    },
    300: {
        name: "EXCHANGED",
        badge: "light"
    },
    400: {
        name: "COMPLETED",
        badge: "dark"
    },
    500: {
        name: "INVOICED",
        badge: "warning"
    },
    600: {
        name: "PAID",
        badge: "info"
    },
    9999: {
        name: "SPAM",
        badge: "danger"
    },
};