import React from "react";
import _ from "underscore";
import { Row, Col, Button, Dropdown, Form } from "react-bootstrap";
import { AdditionalFeesBreakdown } from "./AdditionalFeesBreakdown";
import EditableInput from "../../../common/editableInput";

const AddFeeRow = ({ addFee, setAddFee, editingFee, showSaleFees, additionalFees, addNameToAddFees, createNewFee }) =>
{
	return (
		<Row style={{
			display: "flex",
			marginTop: "30px",
		}}>

			<Col>
				<label>Name: </label>
				<Dropdown drop="up">
					<Dropdown.Toggle
						variant={addFee.code !== "100" || editingFee ? "primary" : "secondary"}
						style={{ minWidth: "250px", textAlign: "left" }}
					>
						{addFee.name || addFee.code == "100" ?
							addFee.code !== "100" || editingFee ? addFee.name : "Enter Fee Name Below"
							:
							"Choose a fee to add"
						}
					</Dropdown.Toggle>
					<Dropdown.Menu>
						{_.map(AdditionalFeesBreakdown, (fee) =>
						{
							if(_.findWhere(showSaleFees ? additionalFees.sell : additionalFees.buy, {name: fee.name}))
								return <Dropdown.Item disabled={true} onClick={()=> addNameToAddFees(fee.code)}>{fee.name}</Dropdown.Item>;

							return <Dropdown.Item
								disabled={fee.code == addFee.code}
								onClick={()=> addNameToAddFees(fee.code)}>{fee.name}
							</Dropdown.Item>;
						}
						)}
					</Dropdown.Menu>
				</Dropdown>
				{addFee.code == 100 && !editingFee ?
					<EditableInput
						placeholder="Add Fee Name"
						value={addFee.name}
						onChange={(e)=> setAddFee({...addFee, name: e.target.value})}
					/>
					:
					null}
			</Col>
			<Col>
				<EditableInput
					placeholder="Amount"
					value={addFee.amount}
					onChange={(e)=> setAddFee({...addFee, amount: e.target.value})}
				/>
			</Col>
			<Col>
				<Form>
					<Form.Label>Is VATable:</Form.Label>
					<Form.Check defaultChecked checked={addFee.isVatable} label="Yes" name="group1" type={"radio"} onClick={()=> setAddFee({ ...addFee, isVatable: true })}/>
					<Form.Check checked={!addFee.isVatable} label="No" name="group1" type={"radio"} onClick={()=> setAddFee({ ...addFee, isVatable: false })}/>
				</Form>
			</Col>
			<Col>
				<label>Type:</label>
				<Dropdown>
					<Dropdown.Toggle
						disabled={addFee.code != "100"}
					>
						{addFee.type || "Other Fee"}
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item onClick={()=> setAddFee({ ...addFee, type: "Other Fee" })}>Other Fee</Dropdown.Item>
						<Dropdown.Item onClick={()=> setAddFee({ ...addFee, type: "Disbursements" })}>Disbursements</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</Col>
			<Col>
				<Button
					style={{ marginTop: "30px"}}
					variant="success"
					onClick={()=> createNewFee(addFee)}
					disabled={!addFee.amount || !addFee.name || !addFee.type}
				>
			Submit
				</Button>
			</Col>
		</Row>
	);
};

export default AddFeeRow;
