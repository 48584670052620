import React from "react";
import { VscDebugDisconnect } from "react-icons/vsc";

export default function FourZeroFour()
{
	return (
		<div
			style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "10em",
			}}
		>
			<div>
				<h1>Oops! Something went wrong!</h1>
			</div>
			<VscDebugDisconnect className="mt-2" size={70} color="orange" />
		</div>
	);
}
