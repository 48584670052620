import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Card, Pagination, Row, Col } from "react-bootstrap";
import { getParameterByName } from "./../../utils/common";
import { getAPI } from "./../../utils/requestAPI";
import TableComponent from "./../common/table";
import { LEAD_STATUS } from "./../../constants/constants";
import SelectList from "./../common/selectList";
import { isBuyOrSellTransaction } from "./../../utils/common";

const VALID_PARAMS = ["partnerId", "page", "status"];

const Leads = () =>
{

	// Currently not used but can be used to extend the query params later
	const initialFilterOptions = () => VALID_PARAMS.map(p => ({key: p, value: getParameterByName(p)})).reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});

	const intialLoadStatus = initialFilterOptions();
	const DEFAULT_STATUS = "ALL_STATUSES";

	const [state, updateState] = useState({
        leads: [],
        activePage: intialLoadStatus.page,
        status: (intialLoadStatus.status || DEFAULT_STATUS)
	});
	const history = useHistory();
	const tableConfig = [
		{
            name: "Lead Id",
            key: "id"
		},
		{
            name: "Full Name",
            key: "fullname",
            type: "link"
		},
		{
            name: "Status",
            key: "status",
            type: "badge"
		},
		{
            name: "Transactions",
            key: "leadTransactions",
            type: "transactions"
		},
		{
            name: "Partner Id",
            key: "partner_id"
		},
		{
            name: "Created At",
            key: "created_at",
            type: "date"
		}
	];

	const fetchData = async(page, currentStatus) =>
	{
		const adminConfig = localStorage.getItem("GC_ADMIN");

		if(!adminConfig)
			return "No admin config set!";

		try
		{
			const GC_ADMIN = JSON.parse(adminConfig);

			let url = `/v1/partners/${GC_ADMIN.partnerId}/leads?page=${page}`;

			if(LEAD_STATUS[currentStatus])
				url = `/v1/partners/${GC_ADMIN.partnerId}/leads?page=${page}&status=${currentStatus}`;

			const leadsRes = await getAPI(url, {skipValidate: true});
			const { data } = leadsRes;
			const { pagination } = data;

			const leads = isBuyOrSellTransaction(data.leads);

			updateState({
                ...state,
                leads,
                pagination,
                activePage: page,
                status: currentStatus
			});

		}
		catch (e)
		{

			console.log(e);

		}

	};

	const loadPageData = (newStatus) =>
	{

		const { activePage, status } = state;

		const statusToUse = LEAD_STATUS[newStatus] ? newStatus : status;

		let searchQuery = `?page=${activePage || 1}`;

		if(LEAD_STATUS[statusToUse])

			searchQuery = `${searchQuery}&status=${statusToUse}`;


		history.push({search: searchQuery,});

		fetchData(activePage || 1, LEAD_STATUS[statusToUse] ? statusToUse : DEFAULT_STATUS);
		updateState({
            ...state,
            activePage,
            status: statusToUse
		});
	};

	useEffect(() =>
	{

		loadPageData();

	}, []);

	const { leads, pagination, activePage } = state;

	const fetchNextPage = nextPage =>
	{
		if(isNaN(nextPage) || nextPage === activePage)
			return;

		let searchQuery = `?page=${nextPage}`;

		if(LEAD_STATUS[state.status])
			searchQuery = `${searchQuery}&status=${state.status}`;

		history.push({search: searchQuery,});

		fetchData(nextPage, LEAD_STATUS[state.status] ? state.status : DEFAULT_STATUS);
	};

	const pageNumbers = (count, currActivePage = 1) =>
	{
		var current = Number(currActivePage),
			last = count,
			delta = 3,
			left = current - delta,
			right = current + delta + 1,
			range = [],
			rangeWithDots = [],
			l;

		for (let i = 1; i <= last; i++)
		{
			if(i === 1 || i === last || i >= left && i < right)

				range.push(i);

		}

		for (let i of range)
		{
			if(l)
			{
				if(i - l === 2)

					rangeWithDots.push(l + 1);

				else if(i - l !== 1)

					rangeWithDots.push("ellipses");

			}

			rangeWithDots.push(i);
			l = i;
		}

		const pages = [];

		for (let i = 0; i < rangeWithDots.length; i++)
		{
			if(rangeWithDots[i] === "ellipses")
			{
				pages.push(<Pagination.Ellipsis />);
			}
			else
			{
				pages.push(<Pagination.Item key={rangeWithDots[i]} active={rangeWithDots[i] === Number(currActivePage)} onClick={() => fetchNextPage(Number(rangeWithDots[i]))}>
					{rangeWithDots[i]}
				</Pagination.Item>);
			}
		}

		return pages;

	};

	const handlStatusChange = (value) =>
	{
		const newStatus = value === DEFAULT_STATUS ? value : Number(value);

		let searchQuery = `?page=${state.activePage}`;

		if(LEAD_STATUS[newStatus])
			searchQuery = `${searchQuery}&status=${newStatus}`;

		history.push({search: searchQuery,});

		updateState({
            ...state,
            status: newStatus
		});

		fetchData(state.activePage, LEAD_STATUS[newStatus] ? newStatus :  DEFAULT_STATUS);

	};

	const pagesForDropdown = {};

	if(pagination)
	{
		for (let i = 1; i <= pagination.pageCount; i++)
			pagesForDropdown[i] = i;
	}

	const statusDropdown = () =>
	{
		const statuses = {ALL_STATUSES: "All Status"};
		const keys = Object.keys(LEAD_STATUS);

		for (let i = 0; i < keys.length; i++)
			statuses[keys[i]] = LEAD_STATUS[keys[i]].name;

		return statuses;
	};

	return (
		<Card className="mt-5">
			<Card.Header>
				<Row className="justify-content-center align-items-center">
					<Col xs="8"><h2 className="mb-0">All Leads</h2></Col>
					<Col xs="4">
						<SelectList
							label="Status"
							lookup={statusDropdown()}
							content={state.status}
							onChange={(e) => handlStatusChange(e.target.value)}
							horizontalAlign={true}
						/>
					</Col>
				</Row>
			</Card.Header>

			{ leads && leads.length ?
				<>
					<TableComponent
						content={leads}
						tableConfig={tableConfig}
					/>
					<Card.Footer>
						<Row>
							<Col sm="10">
								<Pagination
									size="lg"
									className="mb-0"
								>
									<Pagination.First onClick={() => activePage > 1 ? fetchNextPage(1) : () =>
									{}}/>
									<Pagination.Prev onClick={() => activePage > 1 ? fetchNextPage(Number(activePage) - 1) : () =>
									{}}/>
									{pageNumbers(pagination.pageCount, activePage)}
									<Pagination.Next onClick={() => activePage < pagination.pageCount ? fetchNextPage(Number(activePage) + 1) : () =>
									{}}/>
									<Pagination.Last onClick={() => activePage < pagination.pageCount ? fetchNextPage(pagination.pageCount) : () =>
									{}}/>
								</Pagination>
							</Col>
							<Col sm="2">
								<SelectList
									label="Goto"
									lookup={pagesForDropdown}
									onChange={e => fetchNextPage(Number(e.target.value))}
									content={activePage}
									horizontalAlign={true}
								/>
							</Col>
						</Row>
					</Card.Footer>
				</>
				:
				""
			}
		</Card>
	);
};

export default Leads;