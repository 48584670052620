import React from "react";
import { Badge } from "react-bootstrap";
import { LEAD_STATUS } from "./../../../constants/constants";

const StatusBadge = ({ status, className }) =>
{
	const badgeStatus = status || 0;

	return <Badge className={className} variant={LEAD_STATUS[badgeStatus].badge}>{LEAD_STATUS[badgeStatus].name}</Badge>;
};

export default StatusBadge;