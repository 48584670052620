const emailRegExp = new RegExp("^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,10}$", "i");

export const validateOffice = (office) =>
{
	const errors = [];

	// Check name
	if(!office.name || office.name.length < 5)
		errors.push("Name must be at least 5 characters");

	// Check address
	if(!office.address || office.address.length < 5)
		errors.push("Address must be at least 5 characters");

	// Check email
	if(!office.email || !emailRegExp.test(office.email))
		errors.push("Email not valid");

	if(!office.postcode || office.postcode.length < 3)
		errors.push("Postcode not valid");

	// Check phone
	if(
		!office.phone
		|| office.phone.length < 7
		|| office.phone.match(/[A-Z]$/gi)
	)
		errors.push("Phone not valid");


	return errors;
};

export const validateConveyancer = (conveyancer, isPPI = false) =>
{
	const errors = [];

	if(!conveyancer.name || conveyancer.name.length < 5)
		errors.push("Name must be at least 5 characters");

	// Check email
	if(!conveyancer.email)
		errors.push("Email can't be empty");
	else if(! ( conveyancer.email.replace(/ /ig, "").split(",").every(email => emailRegExp.test(email)) ) )
		errors.push("Some of the emails are invalid");

	// Check phone
	if(
		!conveyancer.phone
		|| conveyancer.phone.length < 7
		|| conveyancer.phone.match(/[A-Z]$/gi)
	)
		errors.push("Phone not valid");

	if(conveyancer.rating)
	{
		if(isNaN(Number(conveyancer.rating)))

			errors.push("Not valid rating");

		else if(
			Number(conveyancer.rating) > 5 ||
      Number(conveyancer.rating) < 0
		)

			errors.push("Rating must be between 0 and 5");

	}

	if(isPPI)
	{
		if(conveyancer.pay_per_intro_cap === null || conveyancer.pay_per_intro_cap === undefined)
			errors.push("Please provide a monthly balance cap for conveyancer when on PPI model.");

		if(!conveyancer.pay_per_intro)
			errors.push("Please enter start date for PPI model.");
	}

	return errors;
};

export const validateQuote = (quote) =>
{
	const validateNumber = (num) =>
	{
		if(!num) return true;

		return !isNaN(Number(num));
	};

	const errors = [];

	if(!quote.price_from)
		errors.push("Price from can't be empty ");
	else if(!validateNumber(quote.price_from))
		errors.push("Not valid price from");


	if(!quote.price_to)
		errors.push("Price to can't be empty ");

	else if(!validateNumber(quote.price_to))
		errors.push("Not valid price to");


	if(!quote.quote)
		errors.push("Quote can't be empty ");

	else if(!validateNumber(quote.quote))
		errors.push("Not valid quote");


	/*
    Add this when GC_POC_FEE is fully supported by the form
  */
	// if (!quote.gc_poc_fee) {
	//   errors.push("QC_FEE can't be empty ");
	// } else if (!validateNumber(quote.gc_poc_fee)) {
	//   errors.push("Not valid GC_FEE");
	// }

	const notRequiredParameters = [
		"leasehold",
		"mortgage",
		"unregistered",
		"shared_ownership",
		"first_time_buyer",
		"affordable",
		"disbursements",
	];

	notRequiredParameters.map((key) =>
	{
		if(!validateNumber(quote[key])) errors.push(`${key} is invalid`);
	});

	return errors;
};
