import React, { useState, useEffect } from "react";
import _ from "underscore";
import { Row, Button, Table, Form } from "react-bootstrap";
import EditableInput from "../../../common/editableInput";

const EditFeeBreakdown = ({	showSaleFees, feeToEdit, addFee, createNewFee, additionalFees, editingFee, setEditingFee, loading }) =>
{
	const [editedFee, setEditedFee] = useState({
		name: "",
		type: "",
		code: "",
		isVatable: true,
		amount: ""
	});

	useEffect(()=>
	{
		if(!loading)
			setEditedFee({...addFee, name: addFee.name, type: addFee.type, code: addFee.code, isVatable: addFee.isVatable, amount: addFee.amount});
	}, [editingFee]);

	const NonEditFees = ({ fee }) =>
	{
		return (
			<tr>
				<td></td>
				<td>{fee.name}</td>
				<td>{fee.amount}</td>
				<td>{fee.isVatable ? "Yes" : "No"}</td>
				<td>{fee.type}</td>
			</tr>
		);
	};

	return (
		<Row>
			<Table responsive hover striped borderless size="sm" bordered>
				<thead>
					<tr style={{ wordWrap: "break-word"}}>
						<th></th>
						<th>Name</th>
						<th>Amount</th>
						<th>VATable</th>
						<th>Type</th>
					</tr>
				</thead>
				<tbody>
					{	_.map(showSaleFees ? additionalFees.sell : additionalFees.buy, (fee, i)=>
					{
						if(i !== feeToEdit)
							return <NonEditFees fee={fee}/>;

						return <tr key={i}>
							<td>
								<Button variant="success"
									style={{ padding: "8px" }}
									onClick={()=> createNewFee(editedFee)}
									disabled={!editedFee.amount || !editedFee.type}
								>
									Save
								</Button>
								<Button variant="danger"
									style={{ padding: "8px", marginLeft: "10px" }}
									onClick={()=> setEditingFee(false)}
								>
									Cancel
								</Button>
							</td>
							<td>
								{editedFee.name}
							</td>
							<td>
								<EditableInput
									placeholder="Amount"
									value={editedFee.amount}
									onChange={(e)=> setEditedFee({...editedFee, amount: e.target.value})}
								/>
							</td>
							<td>
								<Form>
									<Form.Label>Is VATable:</Form.Label>
									<Form.Check defaultChecked checked={editedFee.isVatable} label="Yes" name="group1" type={"radio"} onClick={()=> setEditedFee({...editedFee, isVatable: true})}/>
									<Form.Check checked={!editedFee.isVatable} label="No" name="group1" type={"radio"} onClick={()=> setEditedFee({...editedFee, isVatable: false})}/>
								</Form>
							</td>
							<td>
								{editedFee.type}
							</td>
						</tr>;
					})}
				</tbody>
			</Table>

		</Row>
	);
};

export default EditFeeBreakdown;
