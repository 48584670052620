import React from "react";
import { FaUser } from "react-icons/fa";
import { GiHouse } from "react-icons/gi";
import { Dropdown } from "react-bootstrap";

const OmniSearchItem = ({options}) =>
{
	const {type, surname, firstname, name, id, email, partner_internal_id} = options;

	if(type === "lead")
	{
		return (

			<a href={`/leads/${id}`} style={{display: "inline-block"}}>
				<div className="text-wrap dropdown-item">
					<small>
						<b className="text-wrap"><FaUser /> {firstname} {surname} <br/></b>
						{email} <br />
                        ID: {id} <br />
                        PID: {partner_internal_id}
					</small>
				</div>
			</a>
		);
	}

	if(type === "conveyancer")
	{
		return (

			<a href={`/conveyancers/${id}`} style={{display: "inline-block"}}>
				<div className="text-wrap dropdown-item">
					<small>
						<b className="text-wrap"><GiHouse /> {name} <br/></b>
						{email} <br/>
                            ID: { id }
					</small>
				</div>
			</a>

		);
	}

	if(options.paginationOption)
		return <Dropdown.Item><span className="text-danger">Too many results</span></Dropdown.Item>;


	return null;

};

export default OmniSearchItem;