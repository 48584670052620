import React, { useState } from "react";
import _ from "underscore";
import { Row, Button, Card, Table, Tabs, Tab, Spinner } from "react-bootstrap";
import { AdditionalFeesBreakdown } from "./AdditionalFeesBreakdown";
import { postAPI } from "../../../../utils/requestAPI";
import EditFeeBreakdown from "./EditFeeBreakdown";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import AddFeeRow from "./AddFeeRow";

const AdditionalFees = ({ additionalFees, conveyancer, fetchData }) =>
{
	const [showSaleFees, setShowSaleFees] = useState(false);
	const [showAddFee, setShowAddFee] = useState(false);
	const [loading, setLoading] = useState(false);
	const [editingFee, setEditingFee] = useState(false);
	const [feeToEdit, setFeeToEdit] = useState({});
	const [addFee, setAddFee] = useState({
		name: "",
		type: "",
		code: "",
		isVatable: true,
		amount: ""
	});

	const addNameToAddFees = (code) =>
	{
		if(code == "100")
		{
			setAddFee({...addFee, code: code, name: "", type: "Other Fee"});
			return;
		}

		const feeToAdd = _.findWhere(AdditionalFeesBreakdown, {code: code});

		setAddFee({...addFee, name: feeToAdd.name, code: feeToAdd.code, type: feeToAdd.type});
	};

	const handleTabChange = (key) =>
	{
		setShowAddFee(false);
		clearAddFee();

		if(key == "sell")
			setShowSaleFees(true);

		else
			setShowSaleFees(false);
	};

	const sendData = async(feeBreakdown) =>
	{
		setLoading(true);

		const data = {quote_additional_fees: JSON.stringify(feeBreakdown)};

		const res = await postAPI(
			`/v1/admin/conveyancers/${conveyancer.id}`,
			data,
			null,
			"PUT"
		);

		clearAddFee();
		fetchData();
		setLoading(false);
		setEditingFee(false);
		return res;
	};

	const createNewFee = (addFee) =>
	{
		if(isNaN(addFee.amount))
		{
			alert("The amount is not a number!");
			return;
		}

		const feeTitle = (addFee.code !== "100") ?
			_.findWhere(AdditionalFeesBreakdown, {code: addFee.code}).title
			:
			addFee.name.replaceAll(" ", "");

		const feeObject = (showSaleFees ? additionalFees.sell : additionalFees.buy);

		if(!editingFee && (_.findWhere(feeObject, {name: addFee.name}) || _.has(feeObject, feeTitle)))
		{
			alert("This fee has already been created! Please change the name, or update the existing fee");
			return;
		}

		// remove old fees if updating fee.
		const updatedObject = _.omit(feeObject, feeTitle);

		const feeBreakdown = showSaleFees ?
			{buy: additionalFees.buy, sell: {...updatedObject, [feeTitle]: addFee}}
			:
			{buy: {...updatedObject, [feeTitle]: addFee}, sell: additionalFees.sell};

		sendData(feeBreakdown);
	};

	const deleteFee = (quote, i) =>
	{
		const confirm = window.confirm(
			"Are you sure you want to delete this quote?"
		);

		if(!confirm)
			return;

		const removedFeeObject = _.omit(showSaleFees ? additionalFees.sell : additionalFees.buy, i);

		const feeBreakdown = showSaleFees ?
			{buy: additionalFees.buy, sell: removedFeeObject}
			:
			{buy: removedFeeObject, sell: additionalFees.sell};

		sendData(feeBreakdown);
	};

	const editFeeObject = (quote, i) =>
	{
		setShowAddFee(false);
		setFeeToEdit(i);
		setAddFee({...addFee, name: quote.name, amount: quote.amount, code: quote.code, type: quote.type, isVatable: quote.isVatable});
		setEditingFee(true);
	};

	const clearAddFee = () =>
	{
		setAddFee({...addFee, name: "", code: "", amount: ""});
		setEditingFee(false);
	};

	const renderEditOrSaveInterface = (quote, i) => (
		<div className="d-flex">
			<Button
				variant="ghost"
				size="sm"
				onClick={() => deleteFee(quote, i)}
			>
				<AiOutlineDelete />
			</Button>
			<Button
				variant="ghost"
				size="sm"
				onClick={() => editFeeObject(quote, i)}
			>
				<AiOutlineEdit />
			</Button>
		</div>
	);

	const Fees = ({ fees }) =>
		(
			<Table responsive hover striped borderless size="sm" bordered>
				<thead>
					<tr style={{ wordWrap: "break-word"}}>
						<th></th>
						<th>Name</th>
						<th>Amount</th>
						<th>VATable</th>
						<th>Type</th>
					</tr>
				</thead>
				<tbody>
					{	_.map(fees, (fee, i)=>
					{
						return <tr key={i}>
							<td>{renderEditOrSaveInterface(fee, i)}</td>
							<td>{fee.name}</td>
							<td>{fee.amount}</td>
							<td>{fee.isVatable ? "Yes" : "No"}</td>
							<td>{fee.type}</td>
						</tr>;
					})}
				</tbody>
			</Table>
		);

	const FeesTable = ({ fees }) =>
		( editingFee ?
			<EditFeeBreakdown
				showSaleFees={showSaleFees}
				feeToEdit={feeToEdit}
				addFee={addFee}
				createNewFee={createNewFee}
				additionalFees={additionalFees}
				editingFee={editingFee}
				setEditingFee={setEditingFee}
				loading={loading}
			/>
			:
			<Fees fees={fees}/>
		);

	return (
		<div style={{ paddingBottom: "20px"}}>
			<Card style={{ padding: "20px", marginBottom: "0px" }}>
				<Row style={{ display: "flex", flexDirection:"column" , justifyContent: "center"}}>
					<Card.Header as="h2">Additional Fees</Card.Header>
					<Row style={{ display: "flex", alignItems: "center" }}>
						<Tabs
							defaultActiveKey="buy"
							activeKey={showSaleFees ? "sell" : "buy"}
							onSelect={(key)=> handleTabChange(key)}
							style={{ padding: "20px" }}
						>
							<Tab disabled={!showSaleFees || editingFee} eventKey="buy" title="Buy" />
							<Tab disabled={showSaleFees || editingFee} eventKey="sell" title="Sell" />
						</Tabs>
					</Row>
				</Row>
				{showSaleFees == false ?
					<FeesTable fees={additionalFees.buy} title="Buy Fees" />
					:
					<FeesTable fees={additionalFees.sell} title="Sell Fees" />
				}
			</Card>
			<Card style={{ padding: "20px" }}>
				<Row style={{ marginLeft: "30px" }}>
					<Button
						style={{ maxWidth: "30%" }}
						disabled={editingFee}
						onClick={()=> setShowAddFee(!showAddFee)}
					>
						Add Additional {showSaleFees ? "Sell" : "Buy"} Fee
					</Button>
					{loading ? <Spinner style={{ marginLeft: "10px" }}variant="primary" animation="border" /> : null}
				</Row>
				{showAddFee ?
					<AddFeeRow
						addFee={addFee}
						setAddFee={setAddFee}
						editingFee={editingFee}
						showSaleFees={showSaleFees}
						additionalFees={additionalFees}
						addNameToAddFees={addNameToAddFees}
						createNewFee={createNewFee}
					/>
					:
					null
				}
			</Card>
		</div>
	);
};

export default AdditionalFees;
