import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AuthenticatedRoute from "./../../authenticatedRoute";
import GateKeeper from "./../gateKeeper";
import Dashboard from "./../dashboard";
import Leads from "./../lead";
import LeadDetails from "./../leadDetails";
import ConveyancersDashboard from "../conveyancerDashboard";
import ConveyancerPanel from "../conveyancerPanel";

const BaseComponent = () =>
{
	return (
		<Switch>
			<Route path="/login" component={GateKeeper} />
			<AuthenticatedRoute path="/dashboard" component={Dashboard} fullWidth={true} />
			<AuthenticatedRoute path="/leads/:leadId" component={LeadDetails} />
			<AuthenticatedRoute path="/leads" component={Leads} />
			<AuthenticatedRoute path="/conveyancers/:conveyancerId" fullWidth component={ConveyancerPanel} />
			<AuthenticatedRoute path="/conveyancers" component={ConveyancersDashboard} />
			<Redirect to="/login" />
		</Switch>
	);
};

export default BaseComponent;