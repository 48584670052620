import styled from "styled-components";
import { Form } from "react-bootstrap";

export const StyledForm = styled(Form)`
    opacity: ${props => props.disabled ? 0.5 : 1};
    ${props => props.horizontalAlign
		? `.form-group {
            display: flex;
            align-items: center;
            justify-content: center;
            .form-label {
                margin-bottom: 0;
                margin-right: 1.5em;
            }
        }`
		:
		""
}
`;