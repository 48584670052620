import React from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import styled from "styled-components";
import EditableInput from "../../../common/editableInput";
import { SectionTitle } from "./../../../common/section/Section";

const StyledCard = styled(Card)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

export default function OfficePanel({
  editingOffice,
  editingOfficeCopy,
  office,
  handleEditingOfficeCopyChange,
  handleEditOffice,
  handleSaveOffice,
  handleCancelEditOffice,
  handleDeleteOffice,
  children,
})
{
	return (
		<>
			<StyledCard className="p-3">
				<Card.Header>
					<SectionTitle className="mb-0">Office details</SectionTitle>
				</Card.Header>
				<Row className="mb-5 mt-3">
					<Col>
						<EditableInput
							placeholder="Name"
							disabled={!editingOffice}
							value={
								!editingOffice ? office["name"] : editingOfficeCopy["name"]
							}
							onChange={(e) =>
								handleEditingOfficeCopyChange("name", e.target.value)
							}
						/>
					</Col>
					<Col>
						<EditableInput
							placeholder="Address"
							disabled={!editingOffice}
							value={
								!editingOffice
									? office["address"]
									: editingOfficeCopy["address"]
							}
							onChange={(e) =>
								handleEditingOfficeCopyChange("address", e.target.value)
							}
						/>
					</Col>
					<Col>
						<EditableInput
							placeholder="Postcode"
							disabled={!editingOffice}
							value={
								!editingOffice
									? office["postcode"]
									: editingOfficeCopy["postcode"]
							}
							onChange={(e) =>
								handleEditingOfficeCopyChange("postcode", e.target.value)
							}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<EditableInput
							placeholder="Phone"
							disabled={!editingOffice}
							value={
								!editingOffice ? office["phone"] : editingOfficeCopy["phone"]
							}
							onChange={(e) =>
								handleEditingOfficeCopyChange("phone", e.target.value)
							}
						/>
					</Col>
					<Col>
						<EditableInput
							placeholder="Email"
							disabled={!editingOffice}
							value={
								!editingOffice ? office["email"] : editingOfficeCopy["email"]
							}
							onChange={(e) =>
								handleEditingOfficeCopyChange("email", e.target.value)
							}
						/>
					</Col>
					<Col
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-end",
						}}
					>
						{!editingOffice && (
							<>
								<Button className="mr-3" onClick={handleEditOffice}>Edit</Button>
								<Button variant="danger" onClick={handleDeleteOffice}>Delete</Button>
							</>
						)}
						{editingOffice && (
							<Button
								className="mr-3"
								variant="success"
								onClick={handleSaveOffice}
							>Save</Button>
						)}
						{editingOffice && (
							<Button variant="secondary" onClick={handleCancelEditOffice}>Cancel</Button>
						)}
					</Col>
				</Row>
				{children && <Row className="pl-3 pr-3">{children}</Row>}
			</StyledCard>
		</>
	);
}
