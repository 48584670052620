import React, { useState } from "react";
import {AsyncTypeahead, Menu} from "react-bootstrap-typeahead";
import SearchBarItem from "./searchBarItem";
import {withRouter} from "react-router-dom";
import { getAPI } from "../../../utils/requestAPI";

const userSearchUrl = (searchTerm) => `/v1/admin/searchAll.json?searchTerm=${searchTerm}`;

const SearchBar = () =>
{

	const [loading, setLoading] = useState(false);
	const [searchResults, setUserOptions] = useState([]);

	const handleSearch = (query) =>
	{
		if(query === "")
			return;

		setLoading(true);

		getAPI(userSearchUrl(query))
			.then(data =>
			{

				const formatedData = {
					conveyancers: data.conveyancers.map(c => ({...c, type: "conveyancer"})),
					leads: data.leads.map(l => ({...l, type: "lead"})),
				};

				setUserOptions(formatedData);
				setLoading(false);
			})
			.catch(e =>
			{
				setLoading(false);
				console.log(e);
			});
	};

	const {
        conveyancers = [],
        leads = [],
	} = searchResults;

	return ( <>
		<AsyncTypeahead
			id="GSearch"
			isLoading={loading}
			placeholder={"G Search"}
			filterBy={["name", "firstname", "surname", "id", "email", "partner_internal_id"]}
			options={[...conveyancers, ...leads]}
			labelKey="name"
			useCache={false}
			onSearch={handleSearch}
			maxResults={200}
			minLength={2}
			renderMenu={(results, menuProps) =>
				<Menu {...menuProps}>
					{results.map((v, index) => <SearchBarItem options={v} key={index} />)}
				</Menu>
			}

		/>
	</>);
};

export default withRouter(SearchBar);

