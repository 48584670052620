import React from "react";
import { Button } from "react-bootstrap";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";

export default function QuoteRow({
  quote,
  handleEditQuote,
  handleDeleteQuote,
  buttonsDisabled,
})
{
	const prettyValue = (value) =>
	{
		return value ?? "- -";
	};

	const renderEditOrSaveInterface = () => (
		<div className="d-flex">
			<Button
				disabled={buttonsDisabled}
				variant="ghost"
				size="sm"
				onClick={() => handleDeleteQuote(quote)}
			>
				<AiOutlineDelete />
			</Button>
			<Button
				disabled={buttonsDisabled}
				variant="ghost"
				size="sm"
				onClick={() => handleEditQuote(quote)}
			>
				<AiOutlineEdit />
			</Button>
		</div>
	);


	return (
		<>
			<tr>
				<td>{renderEditOrSaveInterface()}</td>
				<td md={1}>{quote.price_from}</td>
				<td md={1}>{quote.price_to}</td>
				<td md={1}>{quote.quote}</td>
				<td md={1}>{prettyValue(quote.gc_ppi_fee)}</td>
				<td md={1}>{prettyValue(quote.leasehold)}</td>
				<td md={1}>{prettyValue(quote.mortgage)}</td>
				<td md={1}>{prettyValue(quote.unregistered)}</td>
				<td md={1}>{prettyValue(quote.shared_ownership)}</td>
				<td md={1}>{prettyValue(quote.first_time_buyer)}</td>
				<td md={1}>{prettyValue(quote.affordable)}</td>
				<td md={1}>{prettyValue(quote.disbursements)}</td>
				<td md={1}>{prettyValue(quote.sdlt_arrangement)}</td>
				<td md={1}>{prettyValue(quote.new_build)}</td>
				<td md={1}>{prettyValue(quote.right_to_buy)}</td>
				<td md={1}>{prettyValue(quote.help_to_buy_redemption)}</td>
				<td md={1}>{prettyValue(quote.help_to_buy_isa)}</td>
				<td md={1}>{prettyValue(quote.help_to_buy_scheme)}</td>
				<td md={1}>{prettyValue(quote.gifted_deposit)}</td>
			</tr>
		</>
	);
}
