import React, { useState, useContext } from "react";
import  { Redirect } from "react-router-dom";
import { FormControl, Button } from "react-bootstrap";
import { getAPI } from "./../../utils/requestAPI";
import { GCAdminContext } from "./../../contexts/gcAdminContext";

const GateKeeper = () =>
{

	const [apiKey, updateApiKey] = useState("");
	const { GC_ADMIN, saveApiKeyInLocal } = useContext(GCAdminContext);

	const loginClick = async() =>
	{

		try
		{

			const checkApiRes = await getAPI(`/v1/admin/checkApiKey/${apiKey}`);

			saveApiKeyInLocal(checkApiRes.data.private_key, checkApiRes.data.partner_id);

		}
		catch (e)
		{
			console.log(e);
		}

	};

	if(GC_ADMIN && GC_ADMIN.apiKey)
	{

		return (
			<Redirect to="/dashboard" />
		);

	}

	return (
		<div id="gateKeeper">
			<div className="container">
				<div className="gatekeeper-form-container">
					<div className="gatekeeper-aligner">
						<h1 className="header-title my-5">Login</h1>
						<FormControl
							type="text"
							placeholder="API Key"
							value={apiKey}
							onChange={e => updateApiKey(e.target.value)}
						/>
						<Button
							className="float-right"
							onClick={loginClick}
						>
                            Login
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GateKeeper;