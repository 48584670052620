import React, { useState } from "react";
import { Form } from "react-bootstrap";

const Checkbox = (props) =>
{
	const {
    label,
    controlId,
    disabled,
    value = false,
    onChange = () =>
    {},
	} = props;

	const [val, updateCheckbox] = useState(value);

	const checkboxOnChange = (v) =>
	{
		updateCheckbox(v.target.checked);
		onChange(v);
	};

	return (
		<Form>
			<Form.Group controlId={controlId}>
				<Form.Check
					type="checkbox"
					label={label}
					disabled={disabled}
					checked={val}
					onChange={checkboxOnChange}
				/>
			</Form.Group>
		</Form>
	);
};

export default Checkbox;
