import React from "react";
import { MenuWrapper, Tab } from "./tabsMenu.styles";

export default function TabsMenu({
  list,
  currentSelectedElement,
  handleCustomButtomClick,
  currentEditingCopy,
  handleSelect,
  buttonPlaceHolder,
  getTabLabel,
})
{
	if(!list) return <div />;

	const isNewQuoteSelected =
    !list ||
    (list &&
      currentSelectedElement &&
      list.every((item) => item.id !== currentSelectedElement.id) &&
      currentEditingCopy);

	return (
		<MenuWrapper>
			{list.map((item, index) =>
			{
				return (
					<Tab
						key={index}
						selected={item.id === currentSelectedElement.id}
						onClick={() => handleSelect(item.id)}
					>
						{getTabLabel ? getTabLabel(item) : item.id}
					</Tab>
				);
			})}
			{buttonPlaceHolder && handleCustomButtomClick && (
				<Tab selected={isNewQuoteSelected} onClick={handleCustomButtomClick}>
					{buttonPlaceHolder}
				</Tab>
			)}
		</MenuWrapper>
	);
}
