import React from "react";
import { Form } from "react-bootstrap";
import { StyledTransactionIcon } from "./TransactionIcon.style";
import { colors } from "./../../../constants/colors";

const TransactionIcon = ({ transactionHasBuy, transactionHasSell}) => (
	<Form>
		<Form.Row>
			{ transactionHasBuy ? (
				<StyledTransactionIcon xs="2" color={colors.red}>
                    B
				</StyledTransactionIcon>
			)
				:
				""
			}
			{ transactionHasSell ? (
				<StyledTransactionIcon xs="2" color={colors.green}>
                    S
				</StyledTransactionIcon>
			)
				:
				""
			}
		</Form.Row>
	</Form>
);

export default TransactionIcon;