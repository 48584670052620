import React, { createContext, useEffect, useState } from "react";

export const GCAdminContext = createContext();

const GCAdminContextProvider = props =>
{

	const getConfig = () =>
	{

		const adminConfig = localStorage.getItem("GC_ADMIN");

		if(adminConfig)
			return JSON.parse(adminConfig);

		return {
            name: null,
            apiKey: null
		};

	};

	const [GC_ADMIN, setGCAdmin] = useState(getConfig());

	useEffect(() =>
	{

		const adminConfig = getConfig();

		if(adminConfig)
			setGCAdmin(adminConfig);

	}, [setGCAdmin]);

	const saveApiKeyInLocal = (apiKey, partnerId) =>
	{

		const obj = {
            apiKey,
            partnerId
		};

		localStorage.setItem("GC_ADMIN", JSON.stringify(obj));

		setGCAdmin(obj);

	};

	return (
		<GCAdminContext.Provider value={{ GC_ADMIN, saveApiKeyInLocal }}>
			{props.children}
		</GCAdminContext.Provider>
	);
};

export default GCAdminContextProvider;
