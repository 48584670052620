import React from "react";
import { StyledLink } from "./NavLink.style";

const NavLink = ({ path, name, className }) =>
{
	return (
		<StyledLink to={path} className={className}>{name}</StyledLink>
	);
};

export default NavLink;