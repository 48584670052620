import { Card, Row } from "react-bootstrap";
import styled from "styled-components";

export const StyledPageTitle = styled.h2`
    font-size: 25px;
    font-weight: bold;
    margin: 25px 0;
`;

export const StyledSectionContainer = styled(Card)`
    background-color: #fff;
    padding: ${props => props.padding || 0}px;
`;

export const StyledRowWithMargin = styled(Row)`
    margin-top: ${props => (props.marginTop || 0)}px;
`;

export const StyledSectionTitle = styled.h4`
    font-size: 16px;
    font-weight: bold;
`;