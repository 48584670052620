import React, { useState, useEffect } from "react";
import { Col, Button, Container, Nav, Card, Row, Form, Table } from "react-bootstrap";
import _ from "underscore";
import moment from "moment";
import Checkbox from "./../common/checkbox";
import TableComponent from "./../common/table";
import TransactionIcon from "./../common/transactionIcon";
import SelectList from "./../common/selectList";
import StatusBadge from "./../common/StatusBadge";
import { getAPI, postAPI } from "./../../utils/requestAPI";
import { gaNiceFormatPrice } from "./../../utils/common";
import EditableInput from "./../common/editableInput";
import { ALL_TRANSACTION_TYPES, SHORTLIST_STATUS, LEAD_STATUS, GET_AGENT_BASE_URL } from "./../../constants/constants";
import { FaExternalLinkAlt } from "react-icons/fa";
import { isBuyOrSellTransaction } from "./../../utils/common";
import { ArrowUpRightSquareFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

const statusLookup = {};
const statusKeys = Object.keys(LEAD_STATUS);

for (let i = 0; i < statusKeys.length; i++)


	statusLookup[statusKeys[i]] = LEAD_STATUS[statusKeys[i]].name;


const LeadDetails = props =>
{

	const [ state, updateState ] = useState({
        activeTransactionType: null,
        disabledStates: {
            details: true,
            transaction: true,
            shortlist: true
        },
        editingCopy: null
	});
	const [relatedLeads, setRelatedLeads] = useState([]);

	useEffect( async() =>
	{

		const { pathname } = props.location;
		const leadId = /[^/]*$/.exec(pathname)[0];
		const partnerId = 1;

		try
		{

			const leadRes = await getAPI(`/v1/partners/${partnerId}/leads/${leadId}`, {skipValidate: true});
			const lead = leadRes.data;
			const availableTransactions = ALL_TRANSACTION_TYPES.filter( a => lead.transactions.find(t => t.type === a.id ) );

			updateState({
                ...state,
                lead,
                availableTransactions,
                activeTransactionType: availableTransactions[0].id
			});


			loadRelatedLeads(lead);
		}
		catch (e)
		{

			console.log(e);

		}

	}, []);

	const toggleEditButton = (section, isCancel, shortlistId) =>
	{
		const { disabledStates, lead, editingCopy } = state;
		const stateKeys = Object.keys(disabledStates);

		for (let i = 0; i < stateKeys.length; i++)

			disabledStates[stateKeys[i]] = section === stateKeys[i] ? !disabledStates[stateKeys[i]] : true;


		if(!isCancel && section === "shortlist")


			saveShortlistStatus(shortlistId);


		updateState({
            ...state,
            disabledStates,
            lead: isCancel ? editingCopy : lead,
            editingCopy: isCancel ? null : JSON.parse(JSON.stringify(lead))
		});
	};

	const saveBasicDetails = async() =>
	{
		const adminConfig = localStorage.getItem("GC_ADMIN");

		if(!adminConfig)
			return "No admin config set!";

		const { lead } = state;

		try
		{

			const GC_ADMIN = JSON.parse(adminConfig);

			await postAPI(`/v1/partners/${GC_ADMIN.partnerId}/leads/${lead.id}`, lead, null, "put");

			toggleEditButton("details");

		}
		catch (e)
		{

			console.log(e);

		}
	};

	const saveShortlistStatus = async shortlistId =>
	{

		const { lead } = state;
		const shortlist = lead.shortlist.find( s => s.id === shortlistId);

		if(!shortlist)
		{

			console.error(`supplied shortlist id "${shortlistId}" is not found in the shortlists`);
			return;

		}

		try
		{

			await postAPI("/v1/admin/changeStatus", {
                leadId: lead.id,
                shortlistId,
                newStatus: shortlist.status
			}, null, "put");

			const shortlistWithHigherStatus = _.max(lead.shortlist, conv => conv.status);

			if(shortlistWithHigherStatus)
			{
				const adminConfig = localStorage.getItem("GC_ADMIN");
				const GC_ADMIN = JSON.parse(adminConfig);

				const newLead = {
                    ...lead,
                    status: shortlistWithHigherStatus.status
				};

				await postAPI(`/v1/partners/${GC_ADMIN.partnerId}/leads/${lead.id}`, newLead, null, "put");

				updateState({
                    ...state,
                    lead: newLead
				});
			}

		}
		catch (e)
		{

			console.error(e);

		}

	};

	const transactionTypeSelected = type => updateState({ ...state, activeTransactionType: type });

	const onDetailsChange = (e, field) =>
	{

		const { lead } = state;
		const { value } = e.target;

		lead[field] = value;

		updateState({
            ...state,
            lead
		});

	};

	const onStatusChange = (id, newStatus) =>
	{
		const { lead } = state;
		const status = Number(newStatus);

		if(!SHORTLIST_STATUS[status])
		{
			console.log(`Invalid lead status selected = ${status}`);
			return false;
		}

		for (let i = 0; i < lead.shortlist.length; i++)
		{
			if(id === lead.shortlist[i].id)

				lead.shortlist[i].status = status;

		}

		updateState({
            ...state,
            lead
		});

	};

	const { lead, editingCopy, disabledStates, activeTransactionType, availableTransactions } = state;

	if(!lead)
		return "";

	const activeTransactionObject = lead.transactions.find( t => t.type === activeTransactionType );

	// the first array is Buy (type: 1), the second array is Sell (type: 2)
	const transactionCheckboxes = [
		[
			{id:"additional_property", name: "Additional Property"},
			{id:"affordable", name: "Affordable"},
			{id:"first_time_buyer", name: "First Time Buyer"},
			{id:"leasehold", name: "Leasehold"},
			{id:"mortgage", name: "Mortgage"},
			{id:"gifted_deposit", name: "Gifted Deposit"},
			{id:"help_to_buy_redemption", name: "Help to buy redemption"},
			{id:"shared_ownership", name: "Shared ownership"},
			{id:"unregistered", name: "Unregistered"}
		],
		[
			{id: "leasehold", name: "Leasehold"},
			{id: "mortgage", name: "Mortgage"},
			{id: "shared_ownership", name: "Shared Ownership"},
			{id: "help_to_buy_redemption", name: "Help to buy redemption"},
			{id: "unregistered", name: "Unregistered"}
		]
	];

	const tableConfig = [
		{
            name: "Name",
            key: "conveyancer.office.name",
            type: "thumbnail",
            thumbnailSrc: "conveyancer.logo"
		},
		{
            name: "Office",
            key: "conveyancer.office.address",
		},
		{
            name: "Status",
            key: "status",
            type: "select",
            editable: true,
            onSelectChange: onStatusChange
		},
		{
			name: "Quote",
			type: "quote",
			key: "totalIncludingVat",
		}
	];

	async function loadRelatedLeads(lead)
	{

		if(!lead) return null;

		const adminConfig = localStorage.getItem("GC_ADMIN");

		if(!adminConfig)
			return "No admin config set!";

		const GC_ADMIN = JSON.parse(adminConfig);

		const leadsRes = await getAPI(`/v1/partners/${GC_ADMIN.partnerId}}/leads?partner_internal_id=${lead.partner_internal_id}`, {skipValidate: true});
		const relatedLeads = isBuyOrSellTransaction(leadsRes.data.leads);
		const filteredRelatedLeads = relatedLeads.filter(currentLead =>
		{
			return currentLead.id !== lead.id;
		});

		setRelatedLeads(filteredRelatedLeads);

	}


	return (
		<Container>
			{ lead ?
				<>
					<h1 className="mt-4">
                        Lead Id: {lead.id}
						<StatusBadge className="ml-5" status={editingCopy ? editingCopy.status : lead.status} />
						{lead.partner_id === 1 && <a className="ml-5" href={`${GET_AGENT_BASE_URL}/admin/vendor/property/${lead.partner_internal_id}/edit`} target="_blank" rel="noreferrer"><FaExternalLinkAlt color="#2D7BE5"/></a>}
					</h1>

					<Card>
						<Card.Header><strong>User Details</strong></Card.Header>
						<Card.Body>
							<Row>
								<Col md={4}>
									<EditableInput
										placeholder="First name"
										value={lead.firstname}
										disabled={disabledStates.details}
										onChange={e => onDetailsChange(e, "firstname")}
									/>
								</Col>
								<Col md={4}>
									<EditableInput
										placeholder="Surname"
										value={lead.surname}
										disabled={disabledStates.details}
										onChange={e => onDetailsChange(e, "surname")}
									/>
								</Col>
								<Col md={4}>
									<Form.Group controlId="formBasicEmail">
										<Form.Label>Created at</Form.Label>
										<Form.Control plaintext readOnly defaultValue={lead.created_at && moment(lead.created_at).format("DD/MM/YYYY")} />

									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col md={4}>
									<EditableInput
										placeholder="Email"
										value={lead.email}
										disabled={disabledStates.details}
										onChange={e => onDetailsChange(e, "email")}
									/>
								</Col>
								<Col md={4}>
									<EditableInput
										placeholder="Phone"
										value={lead.phone}
										disabled={disabledStates.details}
										onChange={e => onDetailsChange(e, "phone")}
									/>
								</Col>
								<Col md={4}>
									<SelectList
										key={Math.random()}
										label="Status"
										lookup={statusLookup}
										disabled={disabledStates.details}
										content={lead.status}
										onChange={e => onDetailsChange(e, "status")}
									/>
								</Col>
							</Row>
							<Row>
								<Col md={4}>
									<Form.Group>
										<Form.Label>Partner Internal ID</Form.Label>
										<Form.Control plaintext readOnly defaultValue={lead.partner_internal_id} />

									</Form.Group>
								</Col>

							</Row>
						</Card.Body>
						<Card.Footer>
							{
								disabledStates.details
									?

									<Button
										variant="primary"
										onClick={() => toggleEditButton("details")}
									>
                                        Edit
									</Button>

									:
									<>
										<Button
											variant="danger"
											onClick={() => toggleEditButton("details", true)}
										>
                                        Cancel
										</Button>
										<Button
											className="float-right"
											variant="success"
											onClick={saveBasicDetails}
										>
                                        Save
										</Button>
									</>
							}

						</Card.Footer>
					</Card>

					{relatedLeads.length > 0 ? (
						<Card>
							<Card.Header>
								<strong>Other related leads</strong>
							</Card.Header>

							<Table striped hover responsive size="lg" className="mb-0">
								<thead>
									<tr>
										<th>ID</th>
										<th>Transactions</th>
										<th>Status</th>
										<th>Created</th>
									</tr>
								</thead>
								<tbody>
									{relatedLeads.map((currentLead, currentLeadIndex) => (
										<tr key={currentLeadIndex}>
											<td>
												<a href={`/leads/${currentLead.id}`} target="_blank" rel="noreferrer">{currentLead.id}</a>
											</td>
											<td>
												<TransactionIcon
													transactionHasBuy={currentLead.transactionHasBuy}
													transactionHasSell={currentLead.transactionHasSell}
												/>
											</td>
											<td><StatusBadge status={currentLead.status} /></td>
											<td>{moment(currentLead.created_at).format("DD/MM/YYYY, h:mm:ss a")}</td>
										</tr>
									))}
								</tbody>
							</Table>

						</Card>
					) : ""}

					{ activeTransactionObject ? (
						<Card>
							<Card.Header>
								<strong>Transactions</strong>
								<Nav variant="tabs" defaultActiveKey={activeTransactionType}>
									{ ALL_TRANSACTION_TYPES.map((t, index) => (
										availableTransactions.find( at => at.id === t.id) ?
											<Nav.Item key={index}>
												<Nav.Link eventKey={t.id} disabled={!availableTransactions.find( at => at.id === t.id)} onClick={() => transactionTypeSelected(t.id)}>{ t.name }</Nav.Link>
											</Nav.Item>

											: null) )}
								</Nav>
							</Card.Header>
							<Card.Body>
								<Row>
									<Col md={2}>
										<Form.Group>
											<Form.Label>Address</Form.Label>
											<Form.Control plaintext readOnly value={activeTransactionObject.address} />
										</Form.Group>
									</Col>
									<Col md={2}>
										<Form.Group>
											<Form.Label>Postcode</Form.Label>
											<Form.Control plaintext readOnly value={activeTransactionObject.postcode} />
										</Form.Group>
									</Col>
									<Col md={2}>
										<Form.Group>
											<Form.Label>Country</Form.Label>
											<Form.Control plaintext readOnly value={activeTransactionObject.country} />
										</Form.Group>
									</Col>
									<Col md={2}>
										<Form.Group>
											<Form.Label>UDPRN</Form.Label>
											<Form.Control plaintext readOnly value={activeTransactionObject.udprn !== 0 ? activeTransactionObject.udprn : null } />
										</Form.Group>
									</Col>
									<Col md={2}>
										<Form.Group>
											<Form.Label>Price</Form.Label>
											<Form.Control plaintext readOnly value={"£" + gaNiceFormatPrice(activeTransactionObject.price)} />
										</Form.Group>
									</Col>
								</Row>
								{activeTransactionObject.perfect_portal_id ? (
									<Row>
										<Col md={2}>
											<Form.Group>
												<Form.Label>Case ID (Perfect Portal)</Form.Label>
												<Form.Control plaintext readOnly value={activeTransactionObject.perfect_portal_id} />
											</Form.Group>
										</Col>
									</Row>
								) : null}
								<Row md="5">
									{
										transactionCheckboxes[activeTransactionType - 1].map( (t, index) =>
											<Col key={index}>
												<Checkbox
													key={index}
													label={t.name}
													value={activeTransactionObject[t.id]}
													disabled={disabledStates.transaction}
													controlId={`${activeTransactionObject[t.id]}-${index}`}
												/>
											</Col>
										)
									}
								</Row>

								{activeTransactionObject.updates && activeTransactionObject.updates.length > 0 ? (
									<>
										<Row className="mt-4 mb-4">
											<Col><b>Transaction updates</b></Col>
										</Row>
										<Row className="mb-3">
											<Col>
												<Row>
													<Col md={4}>Conveyancer</Col>
													<Col md={4}>Event</Col>
													<Col md={4}>Date</Col>
												</Row>
											</Col>
										</Row>
										<Row>
											<Col>
												{
													activeTransactionObject.updates.map( (t, index) =>
														<Row key={index} className="mb-3">
															<Col md={4}>
																<Link to={`/conveyancers/${t.conveyancer.id}`} target="_blank">{t.conveyancer.name}<ArrowUpRightSquareFill className="ml-2" /></Link>
															</Col>
															<Col md={4}>{t.eventTypeString.toUpperCase()}</Col>
															<Col md={4}>{moment(t.created_at).format("DD/MM/YYYY, HH:mm:ss")}</Col>
														</Row>
													)
												}
											</Col>
										</Row>
									</>
								) : null}
							</Card.Body>
						</Card>)
						:
						""
					}
					<Card>
						<Card.Header><strong>Shortlist</strong></Card.Header>

						{
							lead.shortlist && lead.shortlist.length
								?
								<TableComponent
									content={lead.shortlist}
									tableConfig={tableConfig}
									lookup={SHORTLIST_STATUS}
									editModeUpdate={toggleEditButton}
									disableEdit={disabledStates.shortlist}
								/>
								:
								<span className="text-error">No shortlist</span>
						}
					</Card>
				</>
				:
				""
			}
		</Container>
	);

};

export default LeadDetails;