import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Image, Navbar, Nav, Container, Form } from "react-bootstrap";
import Logo from "./../../images/gc-logo.png";
import NavLink from "./../common/NavLink";
import { colors } from "./../../constants/colors";
import { GCAdminContext } from "./../../contexts/gcAdminContext";
import SearchBar from "../common/searchBar";

const Header = () =>
{

	const { GC_ADMIN } = useContext(GCAdminContext);
	const isAuthenticated = !!(GC_ADMIN && GC_ADMIN.apiKey);

	return (
		<Navbar bg="dark" expand="lg" className="p-3 border-0">
			<Container>
				<Nav.Link as={Link} to="/" style={{color: colors.white, fontSize: "16px"}}>
					<Image src={Logo} width="30px"/>
					{" "}
                    GC Admin Panel
				</Nav.Link>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				{ isAuthenticated ?
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="justify-content-end" style={{color: colors.white}}>
							<NavLink path="/dashboard" name="Dashboard" className="nav-link" />
							<NavLink path="/conveyancers" name="Conveyancers" className="nav-link" />
							<NavLink path="/leads" name="Leads" className="nav-link" />
							<Form inline className="ml-5">
								<SearchBar />
							</Form>
						</Nav>
					</Navbar.Collapse>
					:
					""
				}
			</Container>
		</Navbar>
	);
};

export default Header;