import React from "react";
import { StyledForm } from "./SelectList.styles";
import { Form } from "react-bootstrap";

const SelectList = ({ label, lookup, disabled, content, onChange, index, horizontalAlign }) =>
{

	return (
		<StyledForm disabled={disabled} horizontalAlign={horizontalAlign}>
			<Form.Group controlId={label} className={horizontalAlign ? "mb-0" : ""}>
				<Form.Label>{label}</Form.Label>
				<Form.Control as="select" disabled={disabled} onChange={e => onChange(e, index)} value={content}>
					{ lookup ? Object.keys(lookup).map( key => ( <option key={key} value={key}>{lookup[key]}</option> ) ) : "" }
				</Form.Control>
			</Form.Group>
		</StyledForm>
	);

};

export default SelectList;