import React, { useState, useEffect } from "react";
import { Row, Col, Form, Card } from "react-bootstrap";
import DatePicker from "react-datepicker";
import LoadingSpinner from "../conveyancerDashboard/common/loadingSpinner";
import CohortChart from "./cohortChart";
import _ from "underscore";
import { getAPI } from "../../utils/requestAPI";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

const Dashboard = () =>
{
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState(null);
	const [startDate, setStartDate] = useState(new Date());
	const [viewPeriod, setViewPeriod] = useState("weeks");

	const handleChangeViewPeriod = (key) =>
	{
		setViewPeriod(key);
		loadData(startDate, key);
	};

	const handleChangeStartDate = (date) =>
	{
		setStartDate(date);
		loadData(date, viewPeriod);
	};

	const loadData = async(date, view) =>
	{
		setLoading(true);

		const res = await getAPI(
			`/v1/admin/cohortData?date=${moment(date).format(
				"YYYY-MM-DD"
			)}&view=${view}`
		);

		if(res && res.success)
		{
			const tableStatusOrder = [0, 100, 200, 300, 400, 500, 600];

			const LEAD_STATUS_CONTACTED = 100;

			const formatedData = _.mapObject(res.data, (obj) =>
			{
				// sum up all the cohort values to get the total
				obj.total = _.reduce(
					_.values(obj.cohort),
					(memo, num) => memo + num,
					0
				);
				obj.totalVals = 0;

				const newCohort = {};
				let numSoFar = obj.total;

				_.each(tableStatusOrder, (status) =>
				{
					newCohort[status] = numSoFar;

					if(obj.cohort[status])
					{
						numSoFar = numSoFar - obj.cohort[status];

						if(status >= LEAD_STATUS_CONTACTED)
							obj.totalVals += obj.cohort[status];
					}
				});

				obj.cohort = newCohort;
				return obj;
			});

			setData(formatedData);
			setLoading(false);
		}
		else
		{
			setLoading(false);
		}
	};

	useEffect(() =>
	{
		return loadData(startDate, viewPeriod);
	}, []);

	return (
		<div className="mt-5">
			<h1>📋 Analytics Dashboard</h1>
			<Card>
				<Row className="p-3">
					<Col
						style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
						}}
					>
						<p style={{ margin: "0" }}>Cohort Analysis</p>
					</Col>
					<Col
						style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
						}}
					>
						<div className="mr-3">Date</div>
						<DatePicker
							selected={startDate}
							onChange={(date) => handleChangeStartDate(date)}
						/>
					</Col>
					<Col>
						<Form.Group
							as={Row}
							controlId="formPlaintextEmail"
							style={{ marginBottom: "0" }}
							onChange={(e) => handleChangeViewPeriod(e.target.value)}
						>
							<Form.Label column sm="4">
                Change View
							</Form.Label>
							<Col sm="8">
								<Form.Control as="select" value={viewPeriod}>
									<option>weeks</option>
									<option>months</option>
								</Form.Control>
							</Col>
						</Form.Group>
					</Col>
				</Row>
			</Card>

			{loading && <LoadingSpinner />}

			{!loading && !data && (
				<Card
					className="p-5"
					style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
					}}
				>
					<h3 style={{ fontWeight: "bold" }}>No data for your settings 😕</h3>
					<p style={{ fontSize: "0.9rem" }}>
            Tip: Try with a different date...
					</p>
				</Card>
			)}

			{!loading && data && (
				<Card>
					<CohortChart
						filterBy={"ShowAll"}
						view={viewPeriod}
						date={startDate}
						cohortData={data}
					/>
				</Card>
			)}
		</div>
	);
};

export default Dashboard;
