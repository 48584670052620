import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { GCAdminContext } from "./../contexts/gcAdminContext";
import { Container } from "react-bootstrap";

const AuthenticatedRoute = ({ component: Component, ...rest }) =>
{
	const { GC_ADMIN } = useContext(GCAdminContext);
	const isAuthenticated = !!(GC_ADMIN && GC_ADMIN.apiKey);

	return (
		<Route {...rest} render={(props) =>
			(
				isAuthenticated === true
					? <Container fluid={rest.fullWidth || false}><Component {...props} /></Container>
					: <Redirect to="/login" />
			)} />
	);
};

export default AuthenticatedRoute;