import React from "react";
import { Table, Image, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import FourZeroFour from "./FourZeroFour";

export default function ConveyancersTable({ conveyancers })
{
	if(!conveyancers || conveyancers.length === 0)

		return <FourZeroFour />;


	return (
		<Table>
			<thead>
				<tr>

					<th>Name</th>
					<th>Enabled</th>
					<th>Accepting Leads</th>
					<th>Model</th>
					<th>Email</th>
					<th>Phone</th>
					<th>Offices</th>
					<th>Logo</th>
				</tr>
			</thead>
			<tbody>
				{conveyancers.map((conv) =>
				{
					const {
						id,
						name,
						enabled,
						accepting_leads,
						pay_per_intro,
						email: emails,
						phone,
						offices,
						logo,
					} = conv;
					const url = `/conveyancers/${id}`;

					return (
						<tr key={id}>
							<td><Link to={url}>{name}</Link></td>
							<td>{enabled === 1 ? <Badge variant="success">Yes</Badge> : <Badge variant="danger">No</Badge>}</td>
							<td>{accepting_leads === 1 ? <Badge variant="success">Yes</Badge> : <Badge variant="danger">No</Badge>}</td>
							<td>{pay_per_intro ? <Badge variant="success">PPI</Badge> : <Badge variant="danger">POC</Badge>}</td>
							<td>
								{ emails ?
									emails
										.replace(" ", "")
										.split(",")
										.map((email, emailIndex) =>
										{
											return (
												<div key={emailIndex}>
													<a target="_blank" rel="noreferrer" href={`mailto:${email}`}>
														{email}
													</a>
												</div>
											);
										})
									:
									""
								}
							</td>
							<td>
								<p style={{ whiteSpace: "nowrap" }}>
									<a target="_blank" rel="noreferrer" href={`tel:${phone}`}>
										{phone}
									</a>
								</p>
							</td>
							<td>{(offices && offices.length) || 0}</td>
							<td>
								{logo ? (
									<Image
										style={{ maxHeight: "75px", maxWidth: "100px" }}
										src={logo}
										thumbnail
									/>
								) : (
									"N/A"
								)}
							</td>
						</tr>
					);
				})}
			</tbody>
		</Table>
	);
}
