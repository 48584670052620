export const AdditionalFeesBreakdown = {
	caseMgmt: {
	title: "caseMgmt",
	name: "Case management fee",
	type: "Other Fee",
	code: "001",
	isVatable: "",
	amount: 0
	},
	fileStorage: {
	title: "fileStorage",
	name: "File storage fee",
	type: "Other Fee",
	isVatable: "",
	code: "002",
	amount: 0
	},
	caseTracking: {
	title: "caseTracking",
	name: "Case Tracking Fee",
	type: "Other Fee",
	isVatable: "",
	code: "003",
	amount: 0
	},
	dueDiligence: {
	title: "dueDiligence",
	name: "Due Diligence",
	type: "Other Fee",
	isVatable: "",
	code: "004",
	amount: 0
	},
	software: {
	title: "software",
	name: "Legal Software Fee",
	type: "Other Fee",
	isVatable: "",
	code: "005",
	amount: 0
	},
	idCheck: {
	title: "idCheck",
	name: "ID Check",
	type: "Disbursements",
	isVatable: "",
	code: "006",
	amount: 0
	},
	officialRegCopy: {
	title: "officialRegCopy",
	name: "Official Copies of the Registry",
	type: "Disbursements",
	isVatable: "",
	code: "007",
	amount: 0
	},
	chancelRepairIndemnity: {
	title: "chancelRepairIndemnity",
	name: "Chancel Repair Indemnity",
	type: "Disbursements",
	isVatable: "",
	code: "008",
	amount: 0
	},
	bankruptcySearch: {
	title: "bankruptcySearch",
	name: "Bankruptcy Search",
	type: "Disbursements",
	isVatable: "",
	code: "009",
	amount: 0
	},
	tt: {
	title: "tt",
	name: "TT Fee",
	type: "Disbursements",
	isVatable: "true",
	code: "010",
	amount: 0
	},
	lawyerChecker: {
	title: "lawyerChecker",
	name: "Lawyer Checker",
	type: "Disbursements",
	isVatable: "",
	code: "011",
	amount: 0
	},
	landRegCharge: {
	title: "landRegCharge",
	name: "Land Registry Charge Title/Plan",
	type: "Disbursements",
	isVatable: "",
	code: "012",
	amount: 0
	},
	landReg: {
	title: "landReg",
	name: "Land Registry (OS1)",
	type: "Disbursements",
	isVatable: "",
	code: "013",
	amount: 0
	},
	ap1: {
	title: "ap1",
	name: "AP1 Fee",
	type: "Disbursements",
	isVatable: "",
	code: "014",
	amount: 0
	},
	companiesHouseMlro: {
	title: "companiesHouseMlro",
	name: "Companies House MLRO form",
	type: "Disbursements",
	isVatable: "",
	code: "015",
	amount: 0
	},
	companiesHouseSearch: {
	title: "companiesHouseSearch",
	name: "Companies House Search Fee",
	type: "Disbursements",
	isVatable: "",
	code: "016",
	amount: 0
	},
	bankTransferAdminFee: {
	title: "bankTransferAdminFee",
		name: "Bank Transfer Admin Fee",
		type: "Disbursements",
		isVatable: "",
		code: "017",
		amount: 0
	},
	other: {
		title: "other",
			name: "Other Fee",
			type: "",
			isVatable: "",
			code: "100",
			amount: 0
	}
};