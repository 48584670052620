import React, { useState } from "react";
import { Row, Col, Tabs, Tab, Table, Button } from "react-bootstrap";
import _ from "underscore";
import QuoteRow from "./quoteRow";
import EditQuoteRow from "./editQuoteRow";

export default function QuotePanel({
	quotes,
	quote,
	editingQuote,
	editingQuoteCopy,
	handleEditingQuoteCopyChange,
	handleEditQuote,
	handleSaveQuote,
	handleDeleteQuote,
	handleUpdateAllQuotes,
	handleCreateNewQuote,
	editingOffice,
})
{
	const groupedQuotes = _.groupBy(quotes, (q) => q.type);
	const sellQuotes = groupedQuotes["2"];
	const buyQuotes = groupedQuotes["1"];

	const defaultTab =
		sellQuotes && sellQuotes.length > 0
			? "sell"
			: buyQuotes && buyQuotes.length > 0
				? "buy"
				: "sell";

	const [currentQuoteTypeDisplayed, setCurrentQuoteTypeDisplayed] = useState(
		defaultTab === "sell" ? 2 : 1
	);

	const displayedQuotes =
		currentQuoteTypeDisplayed === 2 ? sellQuotes : buyQuotes;

	const handleMenuChange = (key) =>
	{
		if(key === "sell")
			setCurrentQuoteTypeDisplayed(2);

		else if(key === "buy")
			setCurrentQuoteTypeDisplayed(1);

		else
			console.log("Menu name doesn't match!");
	};

	return (
		<>
			<Row className="mb-5">
				<Col>
					<Tabs
						defaultActiveKey={defaultTab}
						activeKey={currentQuoteTypeDisplayed === 2 ? "sell" : "buy"}
						onSelect={handleMenuChange}
					>
						<Tab eventKey="sell" title="Sell" />
						<Tab eventKey="buy" title="Buy" />
					</Tabs>
				</Col>
				<Col style={{ display: "flex", justifyContent: "flex-end" }}>
					<Button
						variant="outline-success"
						onClick={() => handleCreateNewQuote(currentQuoteTypeDisplayed)}
					>
						New Quote
					</Button>
				</Col>
			</Row>
			{displayedQuotes && displayedQuotes.length > 0 && (
				<Table responsive hover striped borderless size="sm" bordered>
					<thead>
						<tr style={{ wordWrap: "break-word" }}>
							<th></th>
							<th>Price from</th>
							<th>Price to</th>
							<th>Quote</th>
							<th>PPI Fee</th>
							<th>Leasehold</th>
							<th>Mortgage</th>
							<th>Unregistered</th>
							<th>Shared Ownership</th>
							<th>First time buyer</th>
							<th>Affordable</th>
							<th>Disbursement</th>
							<th>SDLT arrangement</th>
							<th>New build</th>
							<th>Right to buy</th>
							<th>Help to buy redemption</th>
							<th>Help to buy ISA</th>
							<th>Help to buy scheme</th>
							<th>Gifted deposit</th>
						</tr>
					</thead>
					<tbody>
						{displayedQuotes.map((q, quoteIndex) =>
						{
							if(
								editingQuote &&
								quote &&
								editingQuoteCopy &&
								quote.id === q.id &&
								editingQuoteCopy.id === q.id
							)
							{
								return (
									<EditQuoteRow
										key={quoteIndex}
										quote={q}
										quoteCopy={editingQuoteCopy}
										handleEditQuote={handleEditQuote}
										handleEditingQuoteCopyChange={
											handleEditingQuoteCopyChange
										}
										handleSaveQuote={handleSaveQuote}
										handleUpdateAllQuotes={handleUpdateAllQuotes}
										editingOffice={editingOffice}
									/>
								);
							}

							return (
								<QuoteRow
									key={quoteIndex}
									buttonsDisabled={quote && !quote.id}
									quote={q}
									handleEditQuote={handleEditQuote}
									handleDeleteQuote={handleDeleteQuote}
								/>
							);
						})}
					</tbody>
				</Table>
			)}
			{(!displayedQuotes || displayedQuotes.length === 0) && (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						width: "100%",
						padding: "2rem",
					}}
				>
					<p style={{ margin: "0", color: "rgba(0,0,0,0.5)" }}>
						No quotes found
					</p>
				</div>
			)}
		</>
	);
}
