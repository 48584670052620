import React, { useState, createContext, useEffect } from "react";

export const LoaderContext = createContext();

const LoaderContextProvider = props =>
{

	const [loadingState, setLoadingState] = useState();

	useEffect(() =>
	{

		setLoadingState({
            pageLoading: false,
            apiLoading: false
		});

	}, [setLoadingState]);

	return (
		<LoaderContext.Provider value={{ loadingState, setLoadingState }}>
			{props.children}
		</LoaderContext.Provider>
	);

};

export default LoaderContextProvider;