import React from "react";
import { Button } from "react-bootstrap";
import EditableInput from "../../../common/editableInput";

export default function EditQuoteRow({
  quote,
  quoteCopy,
  handleEditQuote,
  handleEditingQuoteCopyChange,
  handleSaveQuote,
  handleUpdateAllQuotes,
  editingOffice,
})
{

	const renderCrudInterface = () => (
		<div className="d-block width-100">
			<Button
				block
				className="mr-2"
				variant="success"
				size="sm"
				onClick={handleSaveQuote}
				disabled={editingOffice}
			>
				Save
			</Button>
			<Button
				block
				variant="secondary"
				size="sm"
				className="mr-2"
				onClick={() =>
				{
					handleEditQuote(quote);
				}}
			>
				Cancel
			</Button>
			{quote.id && (
				<Button
					block
					style={{ whiteSpace: "nowrap" }}
					className="mr-2"
					variant="outline-success"
					size="sm"
					onClick={handleUpdateAllQuotes}
				>
					Update all
				</Button>
			)}
		</div>
	);

	const editCell = (key) =>
		<td style={{minWidth: INPUT_SIZE.smallWidth}}>
			<EditableInput
				value={quoteCopy[key]}
				onChange={(e) =>
					handleEditingQuoteCopyChange(key, e.target.value)
				}
			/>
		</td>;

	const INPUT_SIZE =
	{
		largeWidth: "150px",
		smallWidth: "100px"
	};

	return (
		<>
			<tr>
				<td>{renderCrudInterface()}</td>

				{editCell("price_from")}
				{editCell("price_to")}
				{editCell("quote")}
				{editCell("gc_ppi_fee")}
				{editCell("leasehold")}
				{editCell("mortgage")}
				{editCell("unregistered")}
				{editCell("shared_ownership")}
				{editCell("first_time_buyer")}
				{editCell("affordable")}
				{editCell("disbursements")}
				{editCell("sdlt_arrangement")}
				{editCell("new_build")}
				{editCell("right_to_buy")}
				{editCell("help_to_buy_redemption")}
				{editCell("help_to_buy_isa")}
				{editCell("help_to_buy_scheme")}
				{editCell("gifted_deposit")}
			</tr>
		</>
	);
}
