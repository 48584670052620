import React from "react";
import moment from "moment";
import { Table, Spinner } from "react-bootstrap";
import _ from "underscore";

const LEAD_STATUS = {
  NEW: 0,
  CONTACTED: 100,
  INSTRUCTED: 200,
  EXCHANGED: 300,
  COMPLETED: 400,
  INVOICED: 500,
  PAID: 600,
};

class CohortChart extends React.Component
{
	constructor(props)
	{
		super(props);
		this.state = {
      cohortData: null,
      tableStatusOrder: [0, 100, 200, 300, 400, 500, 600],
		};

		this.createAndRenderPieChart = this.createAndRenderPieChart.bind(this);
	}

	//Process the Cohort Valuation data
	processCohortValData(cohortData, view, date)
	{
		if(view === "months")
		{
			const startMonth = moment(date).startOf("month").subtract(13, "month");
			const dataTable = [];

			while (moment(date).startOf("month").isSameOrAfter(startMonth, "month"))
			{
				const key = startMonth.format("YYYY-M");

				if(cohortData[key])
				{
					dataTable.push([
						startMonth.format("MMM YYYY"),
						(100 * cohortData[key].autoVals) / cohortData[key].total,
						(100 * (cohortData[key].totalVals - cohortData[key].autoVals)) /
              cohortData[key].total,
					]);
				}
				else
				{
					dataTable.push([startMonth.format("MMM YYYY"), 0, 0]);
				}

				startMonth.add(1, "month");
			}

			return [
				["Month / Year", "Auto Valuations", "CS Valuations"],
				...dataTable,
			];
		}
		else if(view == "weeks")
		{
			const startMonth = moment(date).startOf("week").subtract(12, "week");
			const dataTable = [];

			while (moment(date).startOf("week").isAfter(startMonth, "week"))
			{
				const key = startMonth.format("YYYY") + "-" + startMonth.week();

				startMonth.add(1, "week");
				if(cohortData[key])
				{
					dataTable.push([
						"WB:" + startMonth.startOf("week").format("YYYY-MM-DD"),
						(100 * cohortData[key].autoVals) / cohortData[key].total,
						(100 * (cohortData[key].totalVals - cohortData[key].autoVals)) /
              cohortData[key].total,
					]);
				}
				else
				{
					dataTable.push([
						"WB:" + startMonth.startOf("week").format("YYYY-MM-DD"),
						0,
						0,
					]);
				}
			}

			return [
				["Month / Year", "Auto Valuations", "CS Valuations"],
				...dataTable,
			];
		}
		else if(view == "days")
		{
			const startMonth = moment(date).startOf("day").subtract(14, "day");
			const dataTable = [];

			while (moment(date).startOf("day").isSameOrAfter(startMonth, "day"))
			{
				const key = startMonth.format("YYYY-MM-DD");

				if(cohortData[key])
				{
					dataTable.push([
						key,
						(100 * cohortData[key].autoVals) / cohortData[key].total,
						(100 * (cohortData[key].totalVals - cohortData[key].autoVals)) /
              cohortData[key].total,
					]);
				}
				else
				{
					dataTable.push([startMonth.format("YYYY-MM-DD"), 0, 0]);
				}

				startMonth.add(1, "day");
			}

			return [
				["Month / Year", "Auto Valuations", "CS Valuations"],
				...dataTable,
			];
		}
	}

	createAndRenderPieChart()
	{
		this.processCohortValData(
			this.props.cohortData,
			this.props.view,
			this.props.date
		);
	}

	tableHeader(date, view)
	{
		if(view === "months")
		{
			const startMonth = moment(date).startOf("month").subtract(13, "months");

			const monthHeaders = [<th key="state">State</th>];

			while (moment(date).startOf("month").isSameOrAfter(startMonth, "month"))
			{
				monthHeaders.push(<th key={startMonth.format("M")}>{startMonth.format("MMM YYYY")}</th>);

				startMonth.add(1, "month");
			}

			return <tr>{monthHeaders}</tr>;
		}
		else if(view === "weeks")
		{
			const startMonth = moment(date).startOf("week").subtract(12, "week");

			const monthHeaders = [<th key="state">State</th>];

			while (moment(date).startOf("week").isAfter(startMonth, "week"))
			{
				startMonth.add(1, "week");
				monthHeaders.push(
					<th key={startMonth.format("M")}>WB: {startMonth.startOf("week").format("YYYY-MM-DD")}</th>
				);
			}

			return <tr>{monthHeaders}</tr>;
		}
		else if(view === "days")
		{
			const startMonth = moment(date).startOf("day").subtract(14, "day");

			const monthHeaders = [<th key="state">State</th>];

			while (moment(date).startOf("day").isSameOrAfter(startMonth, "day"))
			{
				monthHeaders.push(<th key={startMonth.format("M")}>{startMonth.format("YYYY-MM-DD")}</th>);

				startMonth.add(1, "day");
			}

			return <tr>{monthHeaders}</tr>;
		}
	}

	cohortTotalValueRow(cohortData, view, date)
	{
		if(view === "months")
		{
			if(!cohortData) return null;

			const startMonth = moment(date).startOf("month").subtract(13, "months");

			const totalCells = [<td key="initial_cell"></td>];

			while (moment(date).startOf("month").isSameOrAfter(startMonth, "month"))
			{
				const key = startMonth.format("YYYY-M");

				if(cohortData[key] && cohortData[key].total > 0)
					totalCells.push(<td key={key}>{cohortData[key].total}</td>);
				else totalCells.push(<td key={key}>0</td>);

				startMonth.add(1, "month");
			}

			return <tr style={{ fontWeight: "bold" }}>{totalCells}</tr>;
		}
		else if(view === "weeks")
		{
			if(!cohortData) return null;

			const startMonth = moment(date).startOf("week").subtract(12, "week");

			const totalCells = [<td key="initial_cell"></td>];

			while (moment(date).startOf("week").isAfter(startMonth, "week"))
			{
				const key = startMonth.format("YYYY") + "-" + startMonth.week();

				if(cohortData[key] && cohortData[key].total > 0)
					totalCells.push(<td key={key}>{cohortData[key].total}</td>);
				else totalCells.push(<td key={key}>0</td>);

				startMonth.add(1, "week");
			}

			return <tr style={{ fontWeight: "bold" }}>{totalCells}</tr>;
		}
		else if(view === "days")
		{
			if(!cohortData) return null;

			const startMonth = moment(date).startOf("day").subtract(14, "day");

			const totalCells = [<td key="initial_cell"></td>];

			while (moment(date).startOf("day").isSameOrAfter(startMonth, "day"))
			{
				const key = startMonth.format("YYYY-MM-DD");

				if(cohortData[key] && cohortData[key].total > 0)
					totalCells.push(<td key={key}>{cohortData[key].total}</td>);
				else totalCells.push(<td key={key}>0</td>);

				startMonth.add(1, "day");
			}

			return <tr style={{ fontWeight: "bold" }}>{totalCells}</tr>;
		}
	}

	cohortTableContents(cohortData, view, date, tableStatusOrder)
	{
		if(view === "months")
		{
			if(!cohortData) return null;

			const tableRows = _.map(tableStatusOrder, (status) =>
			{
				const startMonth = moment(date).startOf("month").subtract(13, "months");
				const cells = [<td key="initial_cell">{_.invert(LEAD_STATUS)[status]}</td>];

				while (
					moment(date).startOf("month").isSameOrAfter(startMonth, "month")
				)
				{
					const key = startMonth.format("YYYY-M");

					if(cohortData[key] && cohortData[key].total > 0)
					{
						const percent =
              (100 * cohortData[key].cohort[status]) / cohortData[key].total;

						cells.push(
							<td
								key={key}
								style={{
                  backgroundColor:
                    "hsl(" + Math.round((percent / 100) * 120) + ", 100%, 80%)",
								}}
							>
								{percent.toFixed(1)}%
							</td>
						);
					}
					else
					{
						cells.push(<td></td>);
					}

					startMonth.add(1, "month");
				}

				return <tr key={status + tableStatusOrder}>{cells}</tr>;
			});

			return tableRows;
		}
		else if(view === "weeks")
		{
			if(!cohortData) return null;

			const tableRows = _.map(tableStatusOrder, (status) =>
			{
				const startMonth = moment(date).startOf("week").subtract(12, "week");
				const cells = [<td key="initial_cell">{_.invert(LEAD_STATUS)[status]}</td>];

				while (moment(date).startOf("week").isAfter(startMonth, "week"))
				{
					const key = startMonth.format("YYYY") + "-" + startMonth.week();

					if(cohortData[key] && cohortData[key].total > 0)
					{
						const percent = (100 * cohortData[key].cohort[status]) / cohortData[key].total;

						cells.push(
							<td
								key={key}
								style={{backgroundColor: "hsl(" + Math.round((percent / 100) * 120) + ", 100%, 80%)",}}
							>
								{percent.toFixed(1)}%
							</td>
						);
					}
					else
					{
						cells.push(<td></td>);
					}

					startMonth.add(1, "week");
				}

				return <tr key={status + tableStatusOrder}>{cells}</tr>;
			});

			return tableRows;
		}
		else if(view === "days")
		{
			if(!cohortData) return null;

			const tableRows = _.map(tableStatusOrder, (status) =>
			{
				const startMonth = moment(date).startOf("day").subtract(14, "day");
				const cells = [<td key="initial_cell">{_.invert(LEAD_STATUS)[status]}</td>];

				while (moment(date).startOf("day").isSameOrAfter(startMonth, "day"))
				{
					const key = startMonth.format("YYYY-MM-DD");

					if(cohortData[key] && cohortData[key].total > 0)
					{
						const percent = (100 * cohortData[key].cohort[status]) / cohortData[key].total;

						cells.push(
							<td
								key={key}
								style={{backgroundColor: "hsl(" + Math.round((percent / 100) * 120) + ", 100%, 80%)",}}
							>
								{percent.toFixed(1)}%
							</td>
						);
					}
					else
					{
						cells.push(<td></td>);
					}

					startMonth.add(1, "day");
				}

				return <tr key={status + tableStatusOrder}>{cells}</tr>;
			});

			return tableRows;
		}
	}

	render()
	{
		const { date, cohortData, view } = this.props;
		const { tableStatusOrder } = this.state;
		//Get Data Table elements
		const tableHeaderRow = this.tableHeader(date, view);
		const tableTotalLeadsRow = this.cohortTotalValueRow(cohortData, view, date);
		const tableContents = this.cohortTableContents(
			cohortData,
			view,
			date,
			tableStatusOrder
		);

		this.createAndRenderPieChart();

		return (
			<div style={{ padding: "10px" }}>
				<p style={{ textAlign: "center" }}>Cohort Status</p>
				{cohortData ? (
					<Table
						striped
						bordered
						hover
						responsive
						size="sm"
						style={{ textAlign: "center" }}
					>
						<thead>{tableHeaderRow}</thead>
						<tbody>
							{tableTotalLeadsRow}
							{tableContents}
						</tbody>
					</Table>
				) : (
					<div className="w-100 text-center">
						<Spinner variant="primary" animation="border" />
					</div>
				)}
			</div>
		);
	}
}

export default CohortChart;
