import React from "react";
import { Form } from "react-bootstrap";
import { colors } from "./../../../constants/colors";

export default function EditableTextarea(props)
{
	const {
    placeholder,
    className,
    disabled,
    value = "",
    onChange = () =>
    {},
	} = props;

	const textStringOnChange = (v) =>
	{
		onChange(v);
	};

	return (
		<>
			<Form>
				<Form.Group controlId="formPlaintextEmail">
					<Form.Label sm="12">{placeholder}</Form.Label>
					<Form.Control
						style={{backgroundColor: disabled ? colors.disabledGrey : "",}}
						className={className || "mb-5"}
						type="text"
						as="textarea"
						rows={5}
						placeholder={placeholder}
						disabled={disabled}
						value={value}
						onChange={textStringOnChange}
					/>
				</Form.Group>
			</Form>
		</>
	);
}
