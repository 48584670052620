import _ from "underscore";
import { ALL_TRANSACTION_TYPES } from "./../constants/constants";

export function getParameterByName(name, url = window.location.href)
{
	name = name.replace(/[[\]]/g, "\\$&");
	var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
		results = regex.exec(url);

	if(!results) return null;

	if(!results[2]) return "";

	return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function isBuyOrSellTransaction(leads)
{
	return leads.map(function(lead)
	{

		lead.fullname = lead.firstname + " " + (lead.surname || "");
		lead.leadTransactions = [];

		for (let i = 0; i < lead.transactions.length; i++)
		{
			const t = ALL_TRANSACTION_TYPES.find(t => t.id === lead.transactions[i].type);

			if(t) lead.leadTransactions.push(t.name);

		}

		lead.transactionHasBuy = !!lead.transactions.find(t => ALL_TRANSACTION_TYPES[0].id === t.type);
		lead.transactionHasSell = !!lead.transactions.find(t => t.type === ALL_TRANSACTION_TYPES[1].id);

		return lead;

	});
}

export function gaNiceFormatPrice(x, precision = 0)
{
	if(x === undefined || x === null)
		return "";

	if(_.isFinite(x))
		x = Math.round(x * 100)/100;

	if(! x.toFixed)
    	x = parseFloat(x);

	return x.toFixed(precision).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}