import React from "react";
import { StyledSectionContainer, StyledRowWithMargin, StyledSectionTitle, StyledPageTitle } from "./Section.styles";

const PageTitle = props => <StyledPageTitle {...props}>{props.children}</StyledPageTitle>;

const SectionContainer = props => <StyledSectionContainer {...props}>{props.children}</StyledSectionContainer>;

const RowWithMargin = props => <StyledRowWithMargin {...props}>{props.children}</StyledRowWithMargin>;

const SectionTitle = props => <StyledSectionTitle {...props}>{props.children}</StyledSectionTitle>;

export {
	PageTitle,
	SectionContainer,
	SectionTitle,
	RowWithMargin
};