import React from "react";
import QuotesTable from "./quotesTable";
import { Card, Col, Row, Button } from "react-bootstrap";
import _ from "underscore";
import { postAPI } from "../../../../utils/requestAPI";
import { validateQuote } from "../../../../utils/validations";
import { SectionTitle } from "./../../../common/section/Section";

export default function QuotesPanel({
	quotes,
	quote,
	editingQuoteCopy,
	editingQuote,
	offices,
	office,
	conveyancer,
	fetchData,
	allHooks,
	editingOffice,
})
{
	const {
		setEditingQuoteCopy,
		setEditingQuote,
		setQuote,
		setQuotes,
		setOffices,
		setLoading,
	} = allHooks;

	const handleEditQuote = (q) =>
	{
		if(!editingQuote && q)
		{
			setQuote(q);
			setEditingQuoteCopy(q);
			setEditingQuote(true);
		}
		else if(editingQuote && quote && q.id !== quote.id)
		{
			if(!quote.id)

				setQuotes(quotes.filter((cQ) => !!cQ.id));


			setQuote(q);
			setEditingQuoteCopy(q);
			setEditingQuote(true);
		}
		else
		{
			if(!quote.id)

				setQuotes(quotes.filter((cQ) => !!cQ.id));


			setQuote(null);
			setEditingQuoteCopy(null);
			setEditingQuote(false);
		}
	};

	const handleEditingQuoteCopyChange = (key, value) =>
	{
		if(editingQuote && editingQuoteCopy)
		{
			let finalValue = value;

			const newEditingQuote = {
				...editingQuoteCopy,
				[key]: finalValue,
			};

			setEditingQuoteCopy(newEditingQuote);
		}
	};

	const handleSaveQuote = async() =>
	{
		const requestParms = [
			"price_from",
			"price_to",
			"gc_poc_fee",
			"gc_ppi_fee",
			"type",
			"quote",
			"leasehold",
			"mortgage",
			"unregistered",
			"shared_ownership",
			"first_time_buyer",
			"affordable",
			"disbursements",
			"sdlt_arrangement",
			"new_build",
			"right_to_buy",
			"help_to_buy_redemption",
			"help_to_buy_isa",
			"help_to_buy_scheme",
			"gifted_deposit"
		];

		// If is not a newQuote update it
		if(quote.id)
		{
			const data = _.pick(editingQuoteCopy, requestParms);

			const res = await postAPI(
				`/v1/admin/conveyancers/${conveyancer.id}/office/${office.id}/quote/${quote.id}`,
				data,
				null,
				"PUT"
			);

			if(res.status === "success")
			{
				setQuote(editingQuoteCopy);
				setEditingQuote(false);

				const newQuotes = quotes.map((currentQuote) =>
				{
					if(currentQuote.id === editingQuoteCopy.id) return editingQuoteCopy;

					return currentQuote;
				});

				const newOffices = offices.map((currentOffice) =>
				{
					if(currentOffice.id === office.id)
					{
						const newOffice = {
							...office,
							quotes: newQuotes,
						};

						return newOffice;
					}

					return currentOffice;
				});

				setEditingQuoteCopy(null);
				setQuote(null);
				setQuotes(newQuotes);
				setOffices(newOffices);
			}
			else if(res.status === "error")
			{
				alert(res.message);
			}
		}
		else
		{
			// Create a new quote

			const data = _.mapObject(
				_.pick(editingQuoteCopy, requestParms),
				(key) => key || null
			);

			// Validate quote
			const errors = validateQuote(data);

			if(errors.length > 0) return alert(errors.join(", "));

			const res = await postAPI(
				`/v1/admin/conveyancers/${conveyancer.id}/office/${office.id}/quote`,
				data,
				null
			);

			if(res.status === "success")
			{
				setQuote(null);
				setEditingQuoteCopy(null);
				setEditingQuote(false);
				setLoading(true);
				fetchData();
			}
			else if(res.status === "error")
			{
				alert(res.message);
			}
		}
	};

	const handleCreateNewQuote = (type) =>
	{
		const emptyQuote = {
			price_from: "",
			price_to: "",
			gc_poc_fee: "",
			gc_ppi_fee: "",
			type,
			quote: "",
			leasehold: "",
			mortgage: "",
			unregistered: "",
			shared_ownership: "",
			first_time_buyer: "",
			affordable: "",
			disbursements: "",
		};

		/*
      Handle when clicking in "create new quote" button and changing the tab section (buy, sell)
    */

		// If Already creating a new quote
		if(editingQuote && quote && !quote.id)
		{
			// Remove the current created quote from the state
			const filteredQuotes = quotes.filter((cQ) => !!cQ.id);

			setQuotes(filteredQuotes);
			setEditingQuote(false);
			setEditingQuoteCopy(null);
			setQuote(null);

			// If clicking "create new quote" in the same tab, just leave.
			if(type === quote.type)
			{
				return;
			}
			else
			{
				// If clicking in a different tab, create a new emptyQuote with that tab type

				setQuote(emptyQuote);
				setEditingQuoteCopy(emptyQuote);
				setQuotes([emptyQuote, ...filteredQuotes]);
				setEditingQuote(true);
			}
		}
		else
		{
			// If not creating any new quote, just add that quote to the state

			setQuote(emptyQuote);
			setEditingQuoteCopy(emptyQuote);
			setQuotes([emptyQuote, ...quotes]);
			setEditingQuote(true);
		}
	};

	const handleDeleteQuote = async(q) =>
	{
		const confirm = window.confirm(
			"Are you sure you want to delete this quote?"
		);

		if(!confirm) return;

		const res = await postAPI(
			`/v1/admin/conveyancers/${conveyancer.id}/office/${office.id}/quote/${q.id}`,
			null,
			null,
			"DELETE"
		);

		if(res.status === "success")
		{
			setLoading(true);
			fetchData();
		}
		else if(res.status === "error")
		{
			alert(res.message);
		}
	};

	const handleUpdateAllQuotes = () =>
	{
		const differences = {};

		_.mapObject(editingQuoteCopy, (value, key) =>
		{
			if(value !== quote[key])

				differences[key] = value;

		});

		if(Object.keys(differences).length > 0)
		{
			const areYouSure = window.confirm(
				"Are you sure you want to update all quotes?"
			);

			if(!areYouSure) return;

			// Update All should update all similar quotes split by type
			// e.g. "active tab" quotes (e.g. sell quotes, buy quotes)
			const { type: editQuoteType } = quote;

			Promise.all(
				quotes.filter(q => q.type === editQuoteType).map((q) =>
				{
					return postAPI(
						`/v1/admin/conveyancers/${conveyancer.id}/office/${office.id}/quote/${q.id}`,
						differences,
						null,
						"PUT"
					);
				}
				)
			).then(() =>
			{
				setQuote(null);
				setEditingQuoteCopy(null);
				setEditingQuote(false);
				fetchData();
			}).catch((err) =>
			{
				return alert(err);
			});
		}
	};

	const handleUpdateAllOfficesQuotes = () =>
	{
		const confirmed = window.confirm(
			"Are you sure you want to update all offices quotes?"
		);

		if(!confirmed) return;

		Promise.all(
			offices.map((currentOffice) =>
			{
				const data = {
					...currentOffice,
					quotes,
				};

				return postAPI(
					`/v1/admin/conveyancers/${conveyancer.id}/office/${currentOffice.id}`,
					data,
					null,
					"PUT"
				);
			})
		).then(() =>
		{
			setLoading(true);
			fetchData();
		}).catch((err) =>
		{
			return alert(err);
		});
	};

	return (
		<Card className="mt-5" style={{ width: "100%" }}>
			<Card.Header>
				<Row>
					<Col
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-start",
						}}
					>
						<SectionTitle>Quotes</SectionTitle>
					</Col>
					<Col
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-end",
						}}
					>
						<Button onClick={handleUpdateAllOfficesQuotes}>
							Copy all quotes
						</Button>
					</Col>
				</Row>
			</Card.Header>
			<Card.Body>
				<QuotesTable
					quotes={quotes}
					editingQuote={editingQuote}
					editingQuoteCopy={editingQuoteCopy}
					quote={quote}
					handleSaveQuote={handleSaveQuote}
					handleEditQuote={handleEditQuote}
					handleEditingQuoteCopyChange={handleEditingQuoteCopyChange}
					handleDeleteQuote={handleDeleteQuote}
					handleUpdateAllQuotes={handleUpdateAllQuotes}
					handleCreateNewQuote={handleCreateNewQuote}
					editingOffice={editingOffice}
				/>
			</Card.Body>
		</Card>
	);
}
