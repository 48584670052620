import React, { useState, useEffect } from "react";
import { getAPI } from "../../utils/requestAPI";
import { Button, Card, Row, Col, Modal } from "react-bootstrap";
import ConveyancersTable from "./common/ConveyancersTable";
import LoadingSpinner from "./common/loadingSpinner";
import EditableInput from "../common/editableInput";
import EditableTextarea from "../common/editableTextarea";
import Checkbox from "../common/checkbox";
import { postAPI } from "../../utils/requestAPI";
import { validateConveyancer } from "../../utils/validations";

const ConveyancerDashboard = () =>
{
	const [loading, setLoading] = useState(true);
	const [conveyancers, setConveyancers] = useState([]);
	const [creatingConveyancer, setCreatingConveyancer] = useState(false);
	const [editConveyancerCopy, setEditConveyancerCopy] = useState(null);

	useEffect(() =>
	{
		return loadAllConveyancers();
	}, []);

	const loadAllConveyancers = async() =>
	{
		const res = await getAPI("/v1/admin/conveyancers/");

		if(res.data && res.data.length > 0)
		{
			setConveyancers(res.data);
			setLoading(false);
		}
		else
		{
			setLoading(false);
		}
	};

	const handleCreateConveyancer = () =>
	{
		if(creatingConveyancer)
		{
			setCreatingConveyancer(false);
			setEditConveyancerCopy(null);
		}
		else
		{
			const emptyConveyancer = {
				name: "",
				email: "",
				phone: "",
				logo: "",
				rating: "",
				description: "",
				cover_england: 0,
				cover_wales: 0,
				cover_scotland: 0,
				cover_ni: 0,
				accepting_leads: 0,
				enabled: 0,
				pay_per_intro: "",
				pay_per_intro_cap: ""
			};

			setEditConveyancerCopy(emptyConveyancer);
			setCreatingConveyancer(true);
		}
	};

	const handleEditConveyancerCopy = (key, value) =>
	{
		let finalValue = value;

		if(typeof value === "boolean") finalValue = value === true ? 1 : 0;

		const newEditingConv = {
			...editConveyancerCopy,
			[key]: finalValue,
		};

		setEditConveyancerCopy(newEditingConv);
	};

	const saveNewConveyancer = async() =>
	{
		const data = {...editConveyancerCopy,};

		// Validate conveyancer data
		const errors = validateConveyancer(data, (!!data.pay_per_intro || !!data.pay_per_intro_cap));

		if(errors.length > 0) return alert(errors.join(", "));

		const res = await postAPI("/v1/admin/conveyancers", data);

		if(res.status === "success")
		{
			handleCreateConveyancer();
			loadAllConveyancers();
		}
		else if(res.status === "error")
		{
			alert(res.message);
		}
		else
		{
			alert("Error saving the conveyancer");
		}
	};

	if(loading)

		return <LoadingSpinner />;


	return (
		<>
			<Card className="mt-5">
				<Card.Header as="h2">
					<Row>
						<Col md={9} sm={6} className="mt-3">
              All Conveyancers
						</Col>
						<Col style={{textAlign: "right"}}>
							<Button variant="success" onClick={handleCreateConveyancer}>
                New Conveyancer
							</Button>
						</Col>
					</Row>
				</Card.Header>

				{!loading && conveyancers.length > 0 && (
					<ConveyancersTable conveyancers={conveyancers} />
				)}

			</Card>

			<Modal show={creatingConveyancer} onHide={handleCreateConveyancer}>
				<Modal.Header closeButton>
					<Modal.Title>Create new conveyancer</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{editConveyancerCopy && (
						<>
							<Row>
								<Col>
									<EditableInput
										placeholder="Name"
										value={editConveyancerCopy["name"]}
										onChange={(e) =>
											handleEditConveyancerCopy("name", e.target.value)
										}
									/>
								</Col>

								<Col>
									<EditableInput
										placeholder="Email"
										value={editConveyancerCopy["email"]}
										onChange={(e) =>
											handleEditConveyancerCopy("email", e.target.value)
										}
									/>
								</Col>

								<Col>
									<EditableInput
										placeholder="Phone"
										value={editConveyancerCopy["phone"]}
										onChange={(e) =>
											handleEditConveyancerCopy("phone", e.target.value)
										}
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<EditableTextarea
										placeholder="Description"
										value={editConveyancerCopy["description"]}
										onChange={(e) =>
											handleEditConveyancerCopy("description", e.target.value)
										}
									/>
								</Col>
							</Row>

							<Row>
								<Col>
									<Checkbox
										label="Accepting leads"
										controlId="conveyancer-accepting-leads"
										value={editConveyancerCopy["accepting_leads"] === 1}
										onChange={(e) =>
											handleEditConveyancerCopy(
												"accepting_leads",
												e.target.checked
											)
										}
									/>
								</Col>

								<Col>
									<Checkbox
										label="Enabled"
										controlId="conveyancer-enabled"
										value={editConveyancerCopy["enabled"] === 1}
										onChange={(e) =>
											handleEditConveyancerCopy("enabled", e.target.checked)
										}
									/>
								</Col>

								<Col>
									<Checkbox
										label="Cover England"
										controlId="conveyancer-cover-england"
										value={editConveyancerCopy["cover_england"] === 1}
										onChange={(e) =>
											handleEditConveyancerCopy(
												"cover_england",
												e.target.checked
											)
										}
									/>
								</Col>
							</Row>

							<Row>
								<Col>
									<Checkbox
										label="Cover Wales"
										controlId="conveyancer-cover-wales"
										value={editConveyancerCopy["cover_wales"] === 1}
										onChange={(e) =>
											handleEditConveyancerCopy("cover_wales", e.target.checked)
										}
									/>
								</Col>

								<Col>
									<Checkbox
										label="Cover Scotland"
										controlId="conveyancer-cover-scotland"
										value={editConveyancerCopy["cover_scotland"] === 1}
										onChange={(e) =>
											handleEditConveyancerCopy(
												"cover_scotland",
												e.target.checked
											)
										}
									/>
								</Col>

								<Col>
									<Checkbox
										label="Cover NI"
										controlId="conveyancer-cover-ni"
										value={editConveyancerCopy["cover_ni"] === 1}
										onChange={(e) =>
											handleEditConveyancerCopy("cover_ni", e.target.checked)
										}
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<EditableInput
										placeholder="PPI Start Date"
										type="date"
										value={editConveyancerCopy["pay_per_intro"]}
										onChange={(e) =>
											handleEditConveyancerCopy("pay_per_intro", e.target.value)
										}
									/>
								</Col>
								<Col>
									<EditableInput
										placeholder="PPI Balance Cap"
										value={editConveyancerCopy["pay_per_intro_cap"]}
										onChange={(e) =>
											handleEditConveyancerCopy("pay_per_intro_cap", e.target.value)
										}
									/>
								</Col>
							</Row>

							<Row>
								<Col sm={4}>
									<EditableInput
										placeholder="Rating"
										value={editConveyancerCopy["rating"]}
										onChange={(e) =>
											handleEditConveyancerCopy("rating", e.target.value)
										}
									/>
								</Col>
								<Col sm={8}>
									<EditableInput
										placeholder="Logo"
										value={editConveyancerCopy["logo"]}
										onChange={(e) =>
											handleEditConveyancerCopy("logo", e.target.value)
										}
									/>
								</Col>
							</Row>
						</>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCreateConveyancer}>
            Close
					</Button>
					<Button variant="success" onClick={saveNewConveyancer}>
            Save Changes
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default ConveyancerDashboard;
