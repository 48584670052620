import { hot } from "react-hot-loader/root";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import GCAdminContextProvider from "./contexts/gcAdminContext";
import LoaderContextProvider from "./contexts/loaderContext";
import BaseComponent from "./components/baseComponent";
import Header from "./components/header";
import "./styles/theme.css";
import "react-bootstrap-typeahead/css/Typeahead.css";

function App()
{
	return (
		<GCAdminContextProvider>
			<LoaderContextProvider>
				<Router>
					<Header />
					<BaseComponent />
				</Router>
			</LoaderContextProvider>
		</GCAdminContextProvider>
	);
}

export default hot(App);